import { useSettings } from '@guider-global/sanity-hooks';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useOverviewTableContext } from 'context';
import {
  getGoalsResponseReceivedCount,
  getLegendItems,
  getStackedBarChartGoalsSeries,
  getStackedBarChartSkillsSeries,
} from 'functions';
import {
  useAdminChartsGoalsCumulative,
  useAdminChartsSkillsCumulative,
  useChartExportOptions,
  useSnackbar,
} from 'hooks';
import { useRef } from 'react';
import { StackedBarChartView } from 'views';

export interface StackedBarChartsContainerProps {
  isLoading?: boolean;
  organizationSlug?: string;
  traineeLabelPluralized?: string;
}

export function StackedBarChartsContainer({
  isLoading = false,
  organizationSlug,
  traineeLabelPluralized,
}: Readonly<StackedBarChartsContainerProps>) {
  const { setError } = useSnackbar();

  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('lg'));
  const today = new Date().toLocaleDateString();

  const goalsBarChartRef = useRef<HTMLDivElement | null>(null);
  const skillsBarChartRef = useRef<HTMLDivElement | null>(null);

  const { settings } = useSettings({
    localeCode: 'en_GB',
  });

  const {
    models: { goalsCumulativeModel, skillsCumulativeModel },
  } = useOverviewTableContext();

  const {
    adminChartsGoalsCumulative = [],
    isLoadingAdminChartsGoalsCumulative,
  } = useAdminChartsGoalsCumulative({
    params: {
      filterModel: goalsCumulativeModel.filterModel,
      sortModel: [
        { field: 'totalCount', sort: 'desc' },
        { field: 'goalCategory', sort: 'asc' },
      ],
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Goals by Category Chart',
        });
      },
    },
  });

  const {
    adminChartsSkillsCumulative = [],
    isLoadingAdminChartsSkillsCumulative,
  } = useAdminChartsSkillsCumulative({
    params: {
      filterModel: skillsCumulativeModel.filterModel,
      sortModel: [
        { field: 'totalCount', sort: 'desc' },
        { field: 'skillCategory', sort: 'asc' },
      ],
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Skills by Category Chart',
        });
      },
    },
  });

  const daysSinceGoalsSurveySent =
    settings?.guider_surveys.goals_survey.days_since_relationship_was_created;

  const daysSinceSkillsSurveySent =
    settings?.guider_surveys.skills_survey.days_since_relationship_was_created;

  const goalsResponseReceivedCount = getGoalsResponseReceivedCount(
    adminChartsGoalsCumulative,
  );

  const goalsChartSeries = getStackedBarChartGoalsSeries({
    palette: palette,
    isMobile: isMobile,
  });

  const skillsChartSeries = getStackedBarChartSkillsSeries({
    palette: palette,
    isMobile: isMobile,
  });

  const goalsLegendItems = getLegendItems(goalsChartSeries);

  const skillsLegendItems = getLegendItems(skillsChartSeries);

  const goalsExportOptions = useChartExportOptions({
    reportPath: 'goals/cumulative',
    filename: `${organizationSlug}-goals-by-category-${today}`,
    filterModel: goalsCumulativeModel.filterModel,
    chartRef: goalsBarChartRef,
    headers: [
      { field: 'goalCategory', headerName: 'Goal Category' },
      { field: 'responseNotReceived', headerName: 'No response' },
      { field: 'goalsHaveChanged', headerName: 'Goals have changed' },
      { field: 'notAchievedYet', headerName: 'Not achieved yet' },
      { field: 'partiallyAchieved', headerName: 'Partially achieved' },
      { field: 'fullyAchieved', headerName: 'Fully achieved' },
      { field: 'totalCount', headerName: 'Total count' },
    ],
  });

  const skillsExportOptions = useChartExportOptions({
    reportPath: 'skills/cumulative',
    filename: `${organizationSlug}-skills-by-category-${today}`,
    filterModel: goalsCumulativeModel.filterModel,
    chartRef: skillsBarChartRef,
    headers: [
      { field: 'skillCategory', headerName: 'Skill Category' },
      { field: 'responseNotReceived', headerName: 'Response not received' },
      {
        field: 'notWorkingOnSkill',
        headerName: 'Not working on this skill at the moment',
      },
      { field: 'notImproved', headerName: 'Not Improved' },
      { field: 'somewhatImproved', headerName: 'Somewhat improved' },
      { field: 'vastlyImproved', headerName: 'Vastly improved' },
      { field: 'totalCount', headerName: 'Total count' },
    ],
  });

  return (
    <Stack direction={'column'} rowGap={'64px'}>
      <StackedBarChartView
        ref={goalsBarChartRef}
        dataKey="goalCategory"
        exportDropdownButtons={goalsExportOptions}
        barChartProps={{
          title: `Top 10 ${traineeLabelPluralized} Goal Status by Category`,
          subtitles: [
            `Users are asked to report on their goals ${daysSinceGoalsSurveySent} 
            days after the relationship has been created • ${goalsResponseReceivedCount} 
            Responses received`,
          ],
          series: goalsChartSeries,
          legendItems: goalsLegendItems,
          dataset: adminChartsGoalsCumulative.slice(0, 10),
          loading: isLoadingAdminChartsGoalsCumulative || isLoading,
          filterModel: goalsCumulativeModel.filterModel,
        }}
      />
      <StackedBarChartView
        ref={skillsBarChartRef}
        dataKey="skillCategory"
        exportDropdownButtons={skillsExportOptions}
        barChartProps={{
          title: 'Top 10 Skill Progression by Category',
          subtitles: [
            `Overview of the skills status reported by the ${traineeLabelPluralized}, 
            these are collected ${daysSinceSkillsSurveySent} 
            days after the relationship has been created`,
          ],
          series: skillsChartSeries,
          legendItems: skillsLegendItems,
          dataset: adminChartsSkillsCumulative.slice(0, 10),
          loading: isLoadingAdminChartsSkillsCumulative || isLoading,
          filterModel: skillsCumulativeModel.filterModel,
        }}
      />
    </Stack>
  );
}
