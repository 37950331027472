import { createContext, useContext } from 'react';

export interface IAppContext {
  organizationSlug?: string;
  isRootDomain: boolean;
  setOrganizationSlug: (organizationSlug: string) => void;
}

export const defaultAppContext: IAppContext = {
  organizationSlug: undefined,
  isRootDomain: false,
  setOrganizationSlug: () => {},
};
export const AppContext = createContext<IAppContext>(defaultAppContext);

export function useAppContext() {
  return useContext(AppContext);
}
