import {
  buildSanityImageUrl,
  useOrganization,
  useSettings,
} from '@guider-global/sanity-hooks';
import { OrganizationIntegration } from '@guider-global/shared-types';
import {
  HubSpotIntegrationModal,
  IntegrationCard,
  JSONSchema,
  NoticeSnackbarProps,
} from '@guider-global/ui';
import { useAppContext } from 'context';
import { useState } from 'react';

export interface HubSpotIntegrationContainerProps {
  integration?: OrganizationIntegration;
  modalOpen: boolean;
  loading?: boolean;
  onNotification: (props: NoticeSnackbarProps) => void;
  onEnableIntegration: (
    data: Partial<OrganizationIntegration>,
  ) => void | Promise<void>;
  onDeleteIntegration: (
    data: Partial<OrganizationIntegration>,
  ) => void | Promise<void>;
  openModal: (modal: boolean) => void;
}

export function HubSpotIntegrationContainer({
  loading,
  integration,
  onNotification,
  onEnableIntegration,
  onDeleteIntegration,
  modalOpen,
  openModal,
}: HubSpotIntegrationContainerProps) {
  const { organizationSlug } = useAppContext();
  const [hubSpotApiToken, setHubSpotApiToken] = useState<string | undefined>(
    '',
  );
  const [hubSpotClientSecret, setHubSpotClientSecret] = useState<
    string | undefined
  >();

  const [hubSpotMappings, setHubSpotMappings] = useState<string | undefined>();
  const [isMappingFormatErrors, setIsMappingFormatErrors] =
    useState<boolean>(false);
  const [isChanges, setIsChanges] = useState<boolean>();

  const { settings } = useSettings({
    localeCode: 'en_GB',
  });

  const { organization } = useOrganization({
    organizationSlug: organizationSlug ?? 'guider',
  });
  const profileFields = organization?.profile_fields ?? [];

  const customSchemaProperties = profileFields.reduce<JSONSchema['properties']>(
    (schema, field) => {
      return {
        ...schema,
        [field.id.current]: {
          type: 'string',
          examples: [field.id.current.toLocaleLowerCase()],
        },
      };
    },
    {},
  );

  const hubSpotImageSrc = buildSanityImageUrl({
    source: settings?.static_media?.integrations.hubspot_logo ?? '',
  });

  const isIntegrationEnabled = Boolean(integration);

  const handleHubSpotEnable = async () => {
    if (!hubSpotApiToken && !isIntegrationEnabled) {
      onNotification({
        show: true,
        message: 'Api token required',
        variant: 'error',
      });
      return;
    }
    if (isMappingFormatErrors) {
      onNotification({
        show: true,
        message: 'Formatting errors',
        variant: 'error',
      });
      return;
    }
    const formatMapping = (jsonString?: string) => {
      try {
        if (!jsonString) return undefined;
        return JSON.parse(jsonString);
      } catch {
        onNotification({
          show: true,
          message: 'Error formatting mappings',
          variant: 'error',
        });
        return undefined;
      }
    };
    await onEnableIntegration({
      integrationName: 'hubspot',
      features: ['profiles:retrieve'],
      ...(hubSpotApiToken && { apiToken: hubSpotApiToken }),
      ...(hubSpotMappings && { mappings: formatMapping(hubSpotMappings) }),
      ...(hubSpotClientSecret && { clientSecret: hubSpotClientSecret }),
    });
  };
  return (
    <>
      <IntegrationCard
        heading={'HubSpot'}
        text={
          'Use HubSpot to pre-populate your users data to enable a smoother experience.'
        }
        avatarSrc={hubSpotImageSrc}
        isConnected={isIntegrationEnabled}
        onClick={() => {
          openModal(true);
        }}
      />
      <HubSpotIntegrationModal
        customSchemaProperties={customSchemaProperties}
        isLoading={loading}
        avatarSrc={hubSpotImageSrc}
        isOpen={modalOpen}
        isConnected={isIntegrationEnabled}
        isUpdateDisabled={!isChanges || isMappingFormatErrors}
        // TODO enable during ticket: #1373
        onClickAlertBarActionLabel={() =>
          window.open(
            'https://docs.google.com/document/d/1GHVGZxohWKfFXtH5zDFBVZ70d0HeXr36wWPnjHVE7Po',
            '_blank',
            'noreferrer',
          )
        }
        onClose={() => openModal(false)}
        // TODO enable during ticket: #1373
        // onClickTest={(event) => {
        //   console.log({ event });
        // }}
        onClickEnable={handleHubSpotEnable}
        onChangeApiToken={(event) => {
          setHubSpotApiToken(event.target.value);
          setIsChanges(true);
        }}
        onClickDisconnect={async (event) => {
          await onDeleteIntegration({ integrationName: 'hubspot' });
        }}
        apiTokenFieldProps={{
          value:
            isIntegrationEnabled && !hubSpotApiToken
              ? '***************'
              : hubSpotApiToken,
        }}
        clientSecretFieldProps={{
          value: hubSpotClientSecret ?? integration?.clientSecret,
        }}
        onChangeClientSecret={(event) => {
          setIsChanges(true);
          setHubSpotClientSecret(event?.target.value);
        }}
        onMappingsChange={({ content }) => {
          setIsChanges(true);
          setHubSpotMappings(content);
        }}
        onMappingErrors={({ errors = [] }) => {
          setIsMappingFormatErrors(() => errors?.length > 0);
        }}
        mappings={
          hubSpotMappings ?? JSON.stringify(integration?.mappings, undefined, 4)
        }
      />
    </>
  );
}
