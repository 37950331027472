import { Button, Stack } from '@guider-global/ui';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import { useAppContext } from 'context';
import { useAdminUserProfiles } from 'hooks';
import { useAdminMatchesV2 } from 'hooks/admin/useAdminMatchesV2';
import { SuggestedMatchesModal } from 'modals';
import { useEffect, useState } from 'react';

export type SuggestedMatchesContainerProps = {
  programSlug?: string;
  onCreateManualMatch: (manualMatchProfileId: string) => void;
  isManualMatchRequestPending: boolean;
  openMatching?: boolean;
  roleName?: string;
};

export function SuggestedMatchesContainer({
  programSlug,
  onCreateManualMatch,
  isManualMatchRequestPending,
  openMatching = false,
  roleName = 'Mentor',
}: SuggestedMatchesContainerProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { adminUserProfiles } = useAdminUserProfiles({});
  const user = adminUserProfiles()[0];
  const userId = user.userId;
  const { isRootDomain, organizationSlug } = useAppContext();

  const { reqAdminMatchesV2, adminMatchesV2, isLoadingAdminMatchesV2 } =
    useAdminMatchesV2({});

  function handleModalOpen() {
    reqAdminMatchesV2({
      method: 'GET',
      url: isRootDomain ? '/superadmin/matchesV2' : '/admin/matchesV2',
      params: {
        resultType: openMatching ? 'guideProfile' : 'guideMembership',
        organizationSlug: isRootDomain
          ? user?.organizationSlug
          : organizationSlug,
        userId,
        programSlug,
      },
    });
    setIsModalOpen(true);
  }
  function handleModalClose() {
    setIsModalOpen(false);
  }

  useEffect(() => {
    if (!isManualMatchRequestPending) {
      handleModalClose();
    }
  }, [isManualMatchRequestPending]);

  const matches = adminMatchesV2();

  return (
    <Stack direction={'row'} justifyContent={'flex-end'}>
      <Button
        variant="textWithIcon"
        startIcon={<PeopleAlt />}
        onClick={handleModalOpen}
        label={`Suggested ${roleName} Matches`}
      />
      <SuggestedMatchesModal
        isLoading={isLoadingAdminMatchesV2}
        programSlug={programSlug}
        matches={matches}
        roleName={roleName}
        isManualMatchRequestPending={isManualMatchRequestPending}
        onCreateManualMatch={onCreateManualMatch}
        handleModalClose={handleModalClose}
        isModalOpen={isModalOpen}
        user={user}
      />
    </Stack>
  );
}
