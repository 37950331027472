import {
  IDateFilterValue,
  IMultiSelectChipFilterOption,
} from '@guider-global/ui';
import { useProgramGroupTableContext } from 'context';
import { addFilterItem } from 'functions';
import { v4 } from 'uuid';
import { MetricsFilterView } from 'views';

export interface IMetricsProgramGroupFilterContainerProps {
  countryOptions: IMultiSelectChipFilterOption[];
  countryEnabled?: boolean;
}

export function MetricsProgramGroupFilterContainer({
  countryOptions,
  countryEnabled,
}: Readonly<IMetricsProgramGroupFilterContainerProps>) {
  const {
    models: { groupsModel, kpiModel, sessionsModel, traineeProfileModel },
    filterValues,
    updateGroupsModel,
    updateTraineeProfileModel,
    updateSessionsModel,
    updateKpiModel,
    updateFilterValues,
  } = useProgramGroupTableContext();
  const { dateRange, email, firstName, lastName, country } = filterValues;

  const handleDateChange = (value: IDateFilterValue) => {
    const to = value.to;
    const from = value.from;
    const dateValue =
      !to && !from
        ? undefined
        : {
            to: to?.toISOString(),
            from: from?.toISOString(),
          };
    const dateRange =
      !to && !from
        ? undefined
        : {
            to: to,
            from: from,
          };

    updateFilterValues({
      dateRange,
    });

    updateGroupsModel({
      filterModel: addFilterItem(groupsModel.filterModel, {
        field: 'createdAt',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
    updateTraineeProfileModel({
      filterModel: addFilterItem(traineeProfileModel.filterModel, {
        field: 'createdAt',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
    updateSessionsModel({
      filterModel: addFilterItem(sessionsModel.filterModel, {
        field: 'sessionStart',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
    updateKpiModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'createdAt',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
  };

  const handleEmailChange = (value?: string) => {
    updateFilterValues({
      email: value,
    });

    updateGroupsModel({
      filterModel: addFilterItem(groupsModel.filterModel, {
        field: 'guideEmail',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateTraineeProfileModel({
      filterModel: addFilterItem(traineeProfileModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateSessionsModel({
      filterModel: addFilterItem(sessionsModel.filterModel, {
        field: 'guideEmail',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateKpiModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
  };
  const handleFirstNameChange = (value?: string) => {
    updateFilterValues({
      firstName: value,
    });

    updateGroupsModel({
      filterModel: addFilterItem(groupsModel.filterModel, {
        field: 'guideFirstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateTraineeProfileModel({
      filterModel: addFilterItem(traineeProfileModel.filterModel, {
        field: 'firstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateSessionsModel({
      filterModel: addFilterItem(sessionsModel.filterModel, {
        field: 'guideFirstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateKpiModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'firstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
  };
  const handleLastNameChange = (value?: string) => {
    updateFilterValues({
      lastName: value,
    });

    updateGroupsModel({
      filterModel: addFilterItem(groupsModel.filterModel, {
        field: 'guideLastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateTraineeProfileModel({
      filterModel: addFilterItem(traineeProfileModel.filterModel, {
        field: 'lastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateSessionsModel({
      filterModel: addFilterItem(sessionsModel.filterModel, {
        field: 'guideLastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateKpiModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'lastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
  };

  const handleCountryChange = (options?: IMultiSelectChipFilterOption[]) => {
    const isValues = options && options?.length > 0;
    const values = isValues
      ? options?.map((option) => option.id)?.join(',')
      : undefined;

    updateGroupsModel({
      filterModel: addFilterItem(groupsModel.filterModel, {
        field: 'guideCountry',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateTraineeProfileModel({
      filterModel: addFilterItem(traineeProfileModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateSessionsModel({
      filterModel: addFilterItem(sessionsModel.filterModel, {
        field: 'guideCountry',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateKpiModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
  };

  return (
    <MetricsFilterView
      dateRange={dateRange}
      onDateChange={handleDateChange}
      email={email}
      onEmailChange={handleEmailChange}
      firstName={firstName}
      onFirstNameChange={handleFirstNameChange}
      lastName={lastName}
      onLastNameChange={handleLastNameChange}
      country={country}
      onCountryChange={handleCountryChange}
      countryOptions={countryOptions}
      updateFilterValues={updateFilterValues}
      countryEnabled={countryEnabled}
    />
  );
}
