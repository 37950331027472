import {
  IDateFilterValue,
  IMultiSelectChipFilterOption,
} from '@guider-global/ui';
import { useOverviewTableContext } from 'context';
import { addFilterItem } from 'functions';
import { v4 } from 'uuid';
import { MetricsFilterView } from 'views';

export interface IMetricsOverviewFilterContainerProps {
  countryOptions: IMultiSelectChipFilterOption[];
  countryEnabled?: boolean;
}
export function MetricsOverviewFilterContainer({
  countryOptions,
  countryEnabled,
}: Readonly<IMetricsOverviewFilterContainerProps>) {
  const {
    models: {
      userModel,
      guideMembershipsModel,
      traineeMembershipsModel,
      goalsModel,
      kpiModel,
      overtimeModel,
      goalsCumulativeModel,
      skillsCumulativeModel,
    },
    filterValues: { firstName, lastName, email, dateRange, country },
    updateGoalsModel,
    updateGuideMembershipsModel,
    updateTraineeMembershipsModel,
    updateUserModel,
    updateKPIModel,
    updateOvertimeModel,
    updateFilterValues,
    updateGoalsCumulativeModel,
    updateSkillsCumulativeModel,
  } = useOverviewTableContext();

  const handleDateChange = (value: IDateFilterValue) => {
    try {
      const to = value.to;
      const from = value.from;
      const dateValue =
        !to && !from
          ? undefined
          : {
              to: to?.toISOString(),
              from: from?.toISOString(),
            };
      const dateRange =
        !to && !from
          ? undefined
          : {
              to: to,
              from: from,
            };
      updateFilterValues({ dateRange });
      updateOvertimeModel({
        filterModel: addFilterItem(overtimeModel.filterModel, {
          field: 'createdAt',
          value: dateValue,
          operator: 'between',
          id: v4(),
        }),
      });
      updateKPIModel({
        filterModel: addFilterItem(kpiModel.filterModel, {
          field: 'createdAt',
          value: dateValue,
          operator: 'between',
          id: v4(),
        }),
      });
      updateUserModel({
        filterModel: addFilterItem(userModel.filterModel, {
          field: 'createdAt',
          value: dateValue,
          operator: 'between',
          id: v4(),
        }),
      });
      updateGuideMembershipsModel({
        filterModel: addFilterItem(guideMembershipsModel.filterModel, {
          field: 'createdAt',
          value: dateValue,
          operator: 'between',
          id: v4(),
        }),
      });
      updateTraineeMembershipsModel({
        filterModel: addFilterItem(traineeMembershipsModel.filterModel, {
          field: 'createdAt',
          value: dateValue,
          operator: 'between',
          id: v4(),
        }),
      });
      updateGoalsModel({
        filterModel: addFilterItem(goalsModel.filterModel, {
          field: 'createdAt',
          value: dateValue,
          operator: 'between',
          id: v4(),
        }),
      });
      updateGoalsCumulativeModel({
        filterModel: addFilterItem(goalsCumulativeModel.filterModel, {
          field: 'createdAt',
          value: dateValue,
          operator: 'between',
          id: v4(),
        }),
      });
      updateSkillsCumulativeModel({
        filterModel: addFilterItem(skillsCumulativeModel.filterModel, {
          field: 'createdAt',
          value: dateValue,
          operator: 'between',
          id: v4(),
        }),
      });
    } catch {
      console.error('insert valid time value');
    }
  };

  const handleEmailChange = (value?: string) => {
    updateOvertimeModel({
      filterModel: addFilterItem(overtimeModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateKPIModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateUserModel({
      filterModel: addFilterItem(userModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateGuideMembershipsModel({
      filterModel: addFilterItem(guideMembershipsModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateTraineeMembershipsModel({
      filterModel: addFilterItem(traineeMembershipsModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateGoalsModel({
      filterModel: addFilterItem(goalsModel.filterModel, {
        field: 'userEmail',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateGoalsCumulativeModel({
      filterModel: addFilterItem(goalsCumulativeModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateSkillsCumulativeModel({
      filterModel: addFilterItem(skillsCumulativeModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
  };
  const handleFirstNameChange = (value?: string) => {
    updateOvertimeModel({
      filterModel: addFilterItem(overtimeModel.filterModel, {
        field: 'firstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateKPIModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'firstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateUserModel({
      filterModel: addFilterItem(userModel.filterModel, {
        field: 'givenName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateGuideMembershipsModel({
      filterModel: addFilterItem(guideMembershipsModel.filterModel, {
        field: 'firstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateTraineeMembershipsModel({
      filterModel: addFilterItem(traineeMembershipsModel.filterModel, {
        field: 'firstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateGoalsModel({
      filterModel: addFilterItem(goalsModel.filterModel, {
        field: 'userFirstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateGoalsCumulativeModel({
      filterModel: addFilterItem(goalsCumulativeModel.filterModel, {
        field: 'firstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateSkillsCumulativeModel({
      filterModel: addFilterItem(skillsCumulativeModel.filterModel, {
        field: 'firstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
  };
  const handleLastNameChange = (value?: string) => {
    updateOvertimeModel({
      filterModel: addFilterItem(overtimeModel.filterModel, {
        field: 'lastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateKPIModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'lastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateUserModel({
      filterModel: addFilterItem(userModel.filterModel, {
        field: 'familyName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateGuideMembershipsModel({
      filterModel: addFilterItem(guideMembershipsModel.filterModel, {
        field: 'lastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateTraineeMembershipsModel({
      filterModel: addFilterItem(traineeMembershipsModel.filterModel, {
        field: 'lastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateGoalsModel({
      filterModel: addFilterItem(goalsModel.filterModel, {
        field: 'userLastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateGoalsCumulativeModel({
      filterModel: addFilterItem(goalsCumulativeModel.filterModel, {
        field: 'lastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateSkillsCumulativeModel({
      filterModel: addFilterItem(skillsCumulativeModel.filterModel, {
        field: 'lastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
  };
  const handleCountryChange = (options?: IMultiSelectChipFilterOption[]) => {
    const isValues = options && options?.length > 0;
    const values = isValues
      ? options?.map((option) => option.id)?.join(',')
      : undefined;
    updateOvertimeModel({
      filterModel: addFilterItem(overtimeModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateKPIModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateUserModel({
      filterModel: addFilterItem(userModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateGuideMembershipsModel({
      filterModel: addFilterItem(guideMembershipsModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateTraineeMembershipsModel({
      filterModel: addFilterItem(traineeMembershipsModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateGoalsModel({
      filterModel: addFilterItem(goalsModel.filterModel, {
        field: 'userCountry',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateGoalsCumulativeModel({
      filterModel: addFilterItem(goalsCumulativeModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateSkillsCumulativeModel({
      filterModel: addFilterItem(skillsCumulativeModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
  };

  return (
    <MetricsFilterView
      dateRange={dateRange}
      onDateChange={handleDateChange}
      email={email}
      onEmailChange={handleEmailChange}
      firstName={firstName}
      onFirstNameChange={handleFirstNameChange}
      lastName={lastName}
      onLastNameChange={handleLastNameChange}
      country={country}
      onCountryChange={handleCountryChange}
      countryOptions={countryOptions}
      updateFilterValues={updateFilterValues}
      countryEnabled={countryEnabled}
    />
  );
}
