import {
  IDateFilterValue,
  IMultiSelectChipFilterOption,
} from '@guider-global/ui';
import { useProgramIndividualTableContext } from 'context';
import { v4 } from 'uuid';
import { MetricsFilterView } from 'views';
import { addFilterItem } from '../../functions';

export interface IMetricsProgramIndividualFilterContainerProps {
  countryOptions: IMultiSelectChipFilterOption[];
  countryEnabled?: boolean;
}

export function MetricsProgramIndividualFilterContainer({
  countryOptions,
  countryEnabled,
}: Readonly<IMetricsProgramIndividualFilterContainerProps>) {
  const {
    models: {
      programGuideModel,
      programTraineeModel,
      programSurveysPostRelationshipModel,
      programSurveysPostSessionModel,
      programActiveRelationshipsModel,
      programConcludedRelationshipsModel,
      programGoalsModel,
      sessionsModel,
      skillsOfferedModel,
      skillsSoughtModel,
      kpiModel,
    },
    filterValues: { firstName, lastName, email, dateRange, country },
    updateProgramGuideModel,
    updateProgramTraineeModel,
    updateProgramActiveRelationshipsModel,
    updateProgramConcludedRelationshipsModel,
    updateProgramGoalsModel,
    updateSessionsModel,
    updateSkillsOffered,
    updateSkillsSought,
    updateKpiModel,
    updateFilterValues,
    updateProgramSurveysPostRelationshipModel,
    updateProgramSurveysPostSessionModel,
  } = useProgramIndividualTableContext();

  const handleDateChange = (value: IDateFilterValue) => {
    const to = value.to;
    const from = value.from;
    const dateValue =
      !to && !from
        ? undefined
        : {
            to: to?.toISOString(),
            from: from?.toISOString(),
          };
    const dateRange =
      !to && !from
        ? undefined
        : {
            to: to,
            from: from,
          };
    updateFilterValues({
      dateRange,
      skillsOfferedDateRange: dateRange,
      skillsSoughtDateRange: dateRange,
    });

    updateKpiModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'createdAt',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
    updateProgramGuideModel({
      filterModel: addFilterItem(programGuideModel.filterModel, {
        field: 'joinedProgramOn',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
    updateProgramTraineeModel({
      filterModel: addFilterItem(programTraineeModel.filterModel, {
        field: 'joinedProgramOn',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
    updateProgramActiveRelationshipsModel({
      filterModel: addFilterItem(programActiveRelationshipsModel.filterModel, {
        field: 'createdAt',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
    updateProgramConcludedRelationshipsModel({
      filterModel: addFilterItem(
        programConcludedRelationshipsModel.filterModel,
        {
          field: 'createdAt',
          value: dateValue,
          operator: 'between',
          id: v4(),
        },
      ),
    });
    updateProgramGoalsModel({
      filterModel: addFilterItem(programGoalsModel.filterModel, {
        field: 'createdAt',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
    updateSessionsModel({
      filterModel: addFilterItem(sessionsModel.filterModel, {
        field: 'sessionStart',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
    updateSkillsOffered({
      filterModel: addFilterItem(skillsOfferedModel.filterModel, {
        field: 'createdAt',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
    updateSkillsSought({
      filterModel: addFilterItem(skillsSoughtModel.filterModel, {
        field: 'createdAt',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
  };

  const handleEmailChange = (value?: string) => {
    updateKpiModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramGuideModel({
      filterModel: addFilterItem(programGuideModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramTraineeModel({
      filterModel: addFilterItem(programTraineeModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramSurveysPostRelationshipModel({
      filterModel: addFilterItem(
        programSurveysPostRelationshipModel.filterModel,
        {
          field: 'email',
          value,
          operator: 'contains',
          id: v4(),
        },
      ),
    });
    updateProgramSurveysPostSessionModel({
      filterModel: addFilterItem(programSurveysPostSessionModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramActiveRelationshipsModel({
      filterModel: addFilterItem(programActiveRelationshipsModel.filterModel, {
        field: 'guideEmail',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramConcludedRelationshipsModel({
      filterModel: addFilterItem(
        programConcludedRelationshipsModel.filterModel,
        {
          field: 'guideEmail',
          value,
          operator: 'contains',
          id: v4(),
        },
      ),
    });
    updateProgramGoalsModel({
      filterModel: addFilterItem(programGoalsModel.filterModel, {
        field: 'userEmail',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateSessionsModel({
      filterModel: addFilterItem(sessionsModel.filterModel, {
        field: 'guideEmail',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
  };
  const handleFirstNameChange = (value?: string) => {
    updateKpiModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'firstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramSurveysPostRelationshipModel({
      filterModel: addFilterItem(
        programSurveysPostRelationshipModel.filterModel,
        {
          field: 'firstName',
          value,
          operator: 'contains',
          id: v4(),
        },
      ),
    });
    updateProgramSurveysPostSessionModel({
      filterModel: addFilterItem(programSurveysPostSessionModel.filterModel, {
        field: 'firstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramGuideModel({
      filterModel: addFilterItem(programGuideModel.filterModel, {
        field: 'firstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramTraineeModel({
      filterModel: addFilterItem(programTraineeModel.filterModel, {
        field: 'firstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramActiveRelationshipsModel({
      filterModel: addFilterItem(programActiveRelationshipsModel.filterModel, {
        field: 'guideFirstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramConcludedRelationshipsModel({
      filterModel: addFilterItem(
        programConcludedRelationshipsModel.filterModel,
        {
          field: 'guideFirstName',
          value,
          operator: 'contains',
          id: v4(),
        },
      ),
    });
    updateProgramGoalsModel({
      filterModel: addFilterItem(programGoalsModel.filterModel, {
        field: 'userFirstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateSessionsModel({
      filterModel: addFilterItem(sessionsModel.filterModel, {
        field: 'guideFirstName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
  };
  const handleLastNameChange = (value?: string) => {
    updateKpiModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'lastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramSurveysPostRelationshipModel({
      filterModel: addFilterItem(
        programSurveysPostRelationshipModel.filterModel,
        {
          field: 'lastName',
          value,
          operator: 'contains',
          id: v4(),
        },
      ),
    });
    updateProgramSurveysPostSessionModel({
      filterModel: addFilterItem(programSurveysPostSessionModel.filterModel, {
        field: 'lastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramGuideModel({
      filterModel: addFilterItem(programGuideModel.filterModel, {
        field: 'lastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramTraineeModel({
      filterModel: addFilterItem(programTraineeModel.filterModel, {
        field: 'lastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramActiveRelationshipsModel({
      filterModel: addFilterItem(programActiveRelationshipsModel.filterModel, {
        field: 'guideLastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateProgramConcludedRelationshipsModel({
      filterModel: addFilterItem(
        programConcludedRelationshipsModel.filterModel,
        {
          field: 'guideLastName',
          value,
          operator: 'contains',
          id: v4(),
        },
      ),
    });
    updateProgramGoalsModel({
      filterModel: addFilterItem(programGoalsModel.filterModel, {
        field: 'userLastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
    updateSessionsModel({
      filterModel: addFilterItem(sessionsModel.filterModel, {
        field: 'guideLastName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
  };

  const handleCountryChange = (options?: IMultiSelectChipFilterOption[]) => {
    const isValues = options && options?.length > 0;
    const values = isValues
      ? options?.map((option) => option.id)?.join(',')
      : undefined;
    updateKpiModel({
      filterModel: addFilterItem(kpiModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateProgramGuideModel({
      filterModel: addFilterItem(programGuideModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateProgramTraineeModel({
      filterModel: addFilterItem(programTraineeModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateProgramActiveRelationshipsModel({
      filterModel: addFilterItem(programActiveRelationshipsModel.filterModel, {
        field: 'guideCountry',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateProgramConcludedRelationshipsModel({
      filterModel: addFilterItem(
        programConcludedRelationshipsModel.filterModel,
        {
          field: 'guideCountry',
          value: values,
          operator: 'includes',
          id: v4(),
        },
      ),
    });
    updateProgramGoalsModel({
      filterModel: addFilterItem(programGoalsModel.filterModel, {
        field: 'userCountry',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
    updateSessionsModel({
      filterModel: addFilterItem(sessionsModel.filterModel, {
        field: 'guideCountry',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
  };

  return (
    <MetricsFilterView
      dateRange={dateRange}
      onDateChange={handleDateChange}
      email={email}
      onEmailChange={handleEmailChange}
      firstName={firstName}
      onFirstNameChange={handleFirstNameChange}
      lastName={lastName}
      onLastNameChange={handleLastNameChange}
      country={country}
      onCountryChange={handleCountryChange}
      countryOptions={countryOptions}
      updateFilterValues={updateFilterValues}
      countryEnabled={countryEnabled}
    />
  );
}
