import { IProfileProgramMembership } from '@guider-global/shared-types';
import {
  AvatarBox,
  DropDownButton,
  InfoIcon,
  PersonIcon,
  PersonOffIcon,
  Stack,
  Text,
  WarningIcon,
} from '@guider-global/ui';
import { MenuItem as MuiMenuItem } from '@mui/material';
import { useAppContext } from 'context';
import { useAdminMemberships } from 'hooks';
import { useParams } from 'react-router-dom';

type MembershipProfileStatusFlags = {
  isPublished?: boolean;
  isBlocked?: boolean;
  requiresApproval?: boolean;
};

type GetMembershipProfileStatusFlagsParams = {
  profileStatus: MembershipProfileStatus;
};

export type MembershipProfileStatus =
  | 'listed'
  | 'unlisted'
  | 'requires-approval'
  | 'blocked';

export type MembershipStatusStackContainerProps = {
  isPublished: boolean;
  isBlocked: boolean;
  requiresApproval: boolean;
  guideMembership: IProfileProgramMembership;
};

export function MembershipStatusStackContainer({
  isPublished,
  isBlocked,
  requiresApproval,
  guideMembership,
}: MembershipStatusStackContainerProps) {
  // Membership Status

  const { reqAdminMemberships } = useAdminMemberships({
    getSilently: false,
  });

  const { userProfileId } = useParams();

  const { organizationSlug, isRootDomain } = useAppContext();
  const GetProfileStatusParams = {
    isPublished,
    isBlocked,
    requiresApproval,
  };
  const profileStatus = (() => {
    const { isPublished, isBlocked, requiresApproval } = GetProfileStatusParams;

    if (isBlocked) return 'blocked';
    if (requiresApproval) return 'requires-approval';
    if (isPublished) return 'listed';
    return 'unlisted';
  })();

  function getMembershipProfileStatusFlags({
    profileStatus,
  }: GetMembershipProfileStatusFlagsParams): MembershipProfileStatusFlags {
    if (profileStatus === 'listed') {
      return {
        isPublished: true,
        isBlocked: false,
        requiresApproval: false,
      };
    }
    if (profileStatus === 'unlisted') {
      return {
        isPublished: false,
        isBlocked: false,
        requiresApproval: false,
      };
    }
    if (profileStatus === 'blocked') {
      return {
        isPublished: false,
        isBlocked: true,
        requiresApproval: false,
      };
    }
    if (profileStatus === 'requires-approval') {
      return {
        isPublished: false,
        isBlocked: false,
        requiresApproval: true,
      };
    }
    return {};
  }

  const updateMembershipProfileStatus = async (
    membershipId: string,
    nextProfileStatus: MembershipProfileStatus,
  ) => {
    const requestUrl = isRootDomain
      ? `/superadmin/memberships/${membershipId}`
      : `/admin/memberships/${membershipId}`;
    const profileStatusFlags = getMembershipProfileStatusFlags({
      profileStatus: nextProfileStatus,
    });

    await reqAdminMemberships({
      method: 'PATCH',
      url: requestUrl,
      data: profileStatusFlags,
      params: {
        ...(organizationSlug && { organizationSlug }),
      },
    });

    reqAdminMemberships({
      url: isRootDomain ? '/superadmin/memberships' : '/admin/memberships',
      params: {
        organizationSlug,
        profileId: userProfileId,
      },
    });
  };

  async function handleUpdateMembershipProfileStatus(
    membershipId: string,
    nextProfileStatus: MembershipProfileStatus,
  ) {
    await updateMembershipProfileStatus(membershipId, nextProfileStatus);
  }
  function onUpdateMembershipProfileStatus(
    membershipId: string,
    nextProfileStatus: MembershipProfileStatus,
  ) {
    handleUpdateMembershipProfileStatus(membershipId, nextProfileStatus);
  }

  function onSelectNewStatus(membershipProfileStatus: MembershipProfileStatus) {
    if (guideMembership && guideMembership!.id) {
      onUpdateMembershipProfileStatus(
        guideMembership.id,
        membershipProfileStatus,
      );
    } else {
      console.error('Guide membership or membership ID is undefined');
    }
  }

  const profileStatusLabel = (() => {
    const status = profileStatus;
    if (status === 'listed') return 'Listed';
    if (status === 'unlisted') return 'Unlisted';
    if (status === 'requires-approval') return 'Requires Approval';
    if (status === 'blocked') return 'Blocked';
    return 'N/A';
  })();

  const profileStatusColor = (() => {
    const status = profileStatus;
    if (status === 'listed') return 'success';
    if (status === 'unlisted') return 'info';
    if (status === 'requires-approval') return 'error';
    if (status === 'blocked') return 'warning';
    return 'info';
  })();

  return (
    <>
      <Stack direction={'column'} spacing={0} pt={1} pr={3}>
        <Text text="Membership Status" variant="h6" sx={{ pt: 2 }} />
        <DropDownButton
          variant="text"
          color={profileStatusColor}
          label={profileStatusLabel}
          sx={{ px: 0, justifyContent: 'flex-start' }}
        >
          <MuiMenuItem onClick={() => onSelectNewStatus('listed')}>
            <AvatarBox
              icon={<PersonIcon size="medium" />}
              size="small"
              heading="Listed"
              subtitles={['Profile is listed and can be matched with.']}
            />
          </MuiMenuItem>
          <MuiMenuItem onClick={() => onSelectNewStatus('unlisted')}>
            <AvatarBox
              icon={<PersonOffIcon size="medium" />}
              size="small"
              heading="Unlisted"
              subtitles={['Profile can not be matched with.']}
            />
          </MuiMenuItem>
          <MuiMenuItem onClick={() => onSelectNewStatus('requires-approval')}>
            <AvatarBox
              icon={<InfoIcon size="medium" />}
              size="small"
              heading="Requires Approval"
              subtitles={['Awaiting admin decision.']}
            />
          </MuiMenuItem>
          <MuiMenuItem onClick={() => onSelectNewStatus('blocked')}>
            <AvatarBox
              icon={<WarningIcon size="medium" />}
              size="small"
              heading="Blocked"
              subtitles={['Profile is blocked from creating profile.']}
            />
          </MuiMenuItem>
        </DropDownButton>
      </Stack>
    </>
  );
}
