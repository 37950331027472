import { ReactNode, createContext } from 'react';
import { ChartDownloaderContextPayload } from './types';
import { useChartDownloaderEngine } from './useChartDownloaderEngine';

export const ChartDownloaderContext =
  createContext<ChartDownloaderContextPayload>({
    createDownload: () => 'n/a',
    startDownload: () => {},
    pauseDownload: () => {},
    cancelDownload: () => {},
    getDownloadConfiguration: () => undefined,
    getDownloadState: () => undefined,
    downloads: [],
  });

export interface ChartDownloaderProviderProps {
  children: ReactNode;
}

export function ChartDownloaderProvider({
  children,
}: ChartDownloaderProviderProps) {
  const chartDownloaderContextPayload = useChartDownloaderEngine();
  return (
    <ChartDownloaderContext.Provider value={chartDownloaderContextPayload}>
      {children}
    </ChartDownloaderContext.Provider>
  );
}
