import {
  getFormattedProgramFields,
  getFormattedSkills,
} from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useBaseLanguage,
} from '@guider-global/sanity-hooks';
import { IProfileProgramOverview, Program } from '@guider-global/shared-types';
import { format } from 'date-fns';
import {
  ProgramMembershipsGuideContent,
  ProgramMembershipsTraineeContent,
  ProgramMembershipsCard,
  UserData,
} from '../../../views/ProgramMembershipsCard';
import { IRelationshipsRow } from '../RelationshipsTableContainer';
import { useMemo } from 'react';
import { ProgramMembershipDetailsViewProps } from '@guider-global/ui';
import { useNavigate } from 'react-router-dom';

export type IndividualProgramMembershipsContainerProps = {
  profileProgramOverview: IProfileProgramOverview;
  program: Program;
  userData: UserData;
};

export function IndividualProgramMembershipsContainer({
  profileProgramOverview,
  program,
  userData,
}: IndividualProgramMembershipsContainerProps) {
  const navigate = useNavigate();
  // Memberships
  const memberships = profileProgramOverview.memberships;
  const relationshipProfilesData = memberships.flatMap(
    (membership) => membership.relationshipProfiles,
  );

  const { baseLanguage } = useBaseLanguage({ localeCode: 'en_GB' });
  const baseLanguageSkillsLabel = baseLanguage?.globals?.skills?.skills_label;

  const programData = useMemo(() => {
    const guideMembership = memberships
      .filter(
        (membership) =>
          profileProgramOverview.programSlug === program?.metadata.id.current &&
          membership.role === 'guide',
      )
      .at(0);

    const traineeMembership = memberships
      .filter(
        (membership) =>
          profileProgramOverview.programName ===
            program?.metadata.program_name && membership.role === 'trainee',
      )
      .at(0);

    const guideRelationshipProfiles = guideMembership
      ? relationshipProfilesData.filter((profile) =>
          guideMembership.relationshipProfiles.includes(profile),
        )
      : [];

    const traineeRelationshipProfiles = traineeMembership
      ? relationshipProfilesData.filter((profile) =>
          traineeMembership.relationshipProfiles.includes(profile),
        )
      : [];

    const programAvatar = buildSanityImageUrl({
      source: program?.program_details?.program_image.asset ?? '',
      width: 300,
    });
    const programCardTitle = program?.metadata.program_name || '';

    const traineeCount = guideRelationshipProfiles.length;
    const guideCount = traineeRelationshipProfiles.length;

    const guideCountLabel = `${guideCount} ${
      guideCount === 1 ? 'Mentor' : 'Mentors'
    }`;
    const traineeCountLabel = `${traineeCount} ${
      traineeCount === 1 ? 'Trainee' : 'Trainees'
    }`;

    const programCardDescription = `${guideCountLabel} • ${traineeCountLabel}`;

    return {
      programAvatar,
      programCardTitle,
      programCardDescription,
      traineeMembership,
      guideMembership,
    };
  }, [
    memberships,
    relationshipProfilesData,
    program?.program_details?.program_image.asset,
    program?.metadata.program_name,
    program?.metadata.id,
    profileProgramOverview.programSlug,
    profileProgramOverview.programName,
  ]);
  const {
    programAvatar,
    programCardTitle,
    programCardDescription,
    traineeMembership,
    guideMembership,
  } = programData;
  // Trainee Membership
  const traineeMembershipData: ProgramMembershipsTraineeContent =
    useMemo(() => {
      //Trainee membership fields
      const formattedTraineeMembershipCreated = traineeMembership?.createdAt
        ? format(new Date(traineeMembership.createdAt), 'dd/MM/yy')
        : 'N/A';

      const traineeSkills = getFormattedSkills({
        role: 'trainee',
        skills: traineeMembership?.skills,
        baseLanguageSkillsLabel,
        newSanitySkills: program?.skills,
        oldSanitySkills: program?.registration?.skills,
      });

      const traineeFields = getFormattedProgramFields(
        traineeMembership,
        program,
      );

      traineeFields.unshift(traineeSkills);

      const traineeDetailsTitle = 'Mentee profile';
      const traineeDetailsDescription = traineeMembership
        ? `Profile Created: ${formattedTraineeMembershipCreated}`
        : '';

      const traineeMembershipDetails: ProgramMembershipDetailsViewProps = {
        title: traineeDetailsTitle,
        description: traineeDetailsDescription,
        fields: traineeFields,
      };

      let traineeRelationshipRows: IRelationshipsRow[] = [];

      if (traineeMembership) {
        traineeRelationshipRows = traineeMembership.relationshipProfiles.map(
          (relationshipProfile) => {
            const {
              displayName,
              sessionCount,
              isConcluded,
              auth0Picture,
              createdAt,
              relationshipId,
              id,
              title,
              picture,
            } = relationshipProfile;

            return {
              id: id,
              auth0Picture: auth0Picture,
              blobPicture: picture,
              name: title || displayName,
              description: createdAt
                ? format(new Date(createdAt), 'dd/MM/yy')
                : 'N/A',
              numberOfSessions: sessionCount?.toString() || '0',
              status: isConcluded,
              relationshipId: relationshipId,
              title: title,
              onClick: () => navigate(`/users/${id}`),
            };
          },
        );
      }
      return {
        traineeMembershipDetails,
        traineeRelationshipRows,
        traineeTableEmptyState: 'No mentors yet',
      };
    }, [program, traineeMembership, navigate]);

  const guideMembershipData: ProgramMembershipsGuideContent = useMemo(() => {
    const formattedGuideMembershipCreated = guideMembership?.createdAt
      ? format(new Date(guideMembership.createdAt), 'dd/MM/yy')
      : 'N/A';

    const guideSkills = getFormattedSkills({
      role: 'guide',
      skills: guideMembership?.skills,
      baseLanguageSkillsLabel,
      newSanitySkills: program?.skills,
      oldSanitySkills: program?.registration?.skills,
    });

    const guideFields = getFormattedProgramFields(guideMembership, program);

    guideFields.unshift(guideSkills);

    const guideDetailsTitle = 'Mentor profile';
    const guideDetailsDescription = guideMembership
      ? `Profile Created: ${formattedGuideMembershipCreated}`
      : '';

    const guideMembershipDetails: ProgramMembershipDetailsViewProps = {
      title: guideDetailsTitle,
      description: guideDetailsDescription,
      fields: guideFields,
    };

    let guideRelationshipRows: IRelationshipsRow[] = [];

    if (guideMembership) {
      guideRelationshipRows = guideMembership.relationshipProfiles.map(
        (relationshipProfile) => {
          const {
            displayName,
            sessionCount,
            isConcluded,
            auth0Picture,
            createdAt,
            relationshipId,
            id,
            title,
            picture,
          } = relationshipProfile;

          return {
            id: id,
            auth0Picture: auth0Picture,
            blobPicture: picture,
            name: title || displayName,
            description: createdAt
              ? format(new Date(createdAt), 'dd/MM/yy')
              : 'N/A',
            numberOfSessions: sessionCount?.toString() || '0',
            status: isConcluded,
            relationshipId: relationshipId,
            title: title,
            onClick: () => navigate(`/users/${id}`),
          };
        },
      );
    }
    const guideMembershipStatus = {
      isPublished: !!guideMembership?.isPublished,
      isBlocked: !!guideMembership?.isBlocked || false,
      requiresApproval: !!guideMembership?.requiresApproval || false,
    };
    return {
      guideMembershipDetails,
      guideRelationshipRows,
      guideTableEmptyState: 'No mentees yet',
      guideMembershipStatus: guideMembershipStatus,
    };
  }, [guideMembership, program, navigate]);
  const guideRelationshipsTableHeaders = {
    name: 'Mentees',
    status: 'Relationship status',
    numberOfSessions: 'Sessions held',
    manage: 'Manage',
  };

  const traineeRelationshipsTableHeaders = {
    name: 'Mentors',
    status: 'Relationship status',
    numberOfSessions: 'Sessions held',
    manage: 'Manage',
  };
  return (
    <ProgramMembershipsCard
      profileProgramOverview={profileProgramOverview}
      title={programCardTitle}
      description={programCardDescription}
      avatarSrc={programAvatar}
      guideMembership={guideMembership}
      traineeMembership={traineeMembership}
      userData={userData}
      guideMembershipContent={guideMembershipData}
      traineeMembershipContent={traineeMembershipData}
      guideRelationshipTableHeaders={guideRelationshipsTableHeaders}
      traineeRelationshipTableHeaders={traineeRelationshipsTableHeaders}
    />
  );
}
