import { Snackbar, Stack } from '@mui/material';
import { useChartDownloader } from 'hooks/useChartDownloader';
import { ChartDownloadStatusContainer } from 'containers/ChartDownloadStatusContainer';

export function ChartDownloaderNoticeContainer() {
  const chartDownloader = useChartDownloader();

  const visibleDownloads = chartDownloader.downloads.filter((download) => {
    if (download.state.isCancelled) return false;
    return true;
  });

  return (
    <Snackbar
      open={visibleDownloads.length > 0}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Stack direction="column" sx={{ flexDirection: 'column' }}>
        {visibleDownloads.map((download) => (
          <ChartDownloadStatusContainer download={download} />
        ))}
      </Stack>
    </Snackbar>
  );
}
