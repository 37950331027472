import { SelectOptionPrograms } from '@guider-global/shared-types';
import { IDateFilterValue, Stack, TFilter } from '@guider-global/ui';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useMediaQuery, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useAppContext, useProgramIndividualTableContext } from 'context';
import { addFilterItem, parseDateFilterToString } from 'functions';
import { useAdminChartsSkillsOffered, useAdminChartsSkillsSought } from 'hooks';
import { v4 } from 'uuid';
import { SkillsMetricsTableView } from 'views';

export interface SkillsMetricsTableContainerProps {
  programSlug: string;
  sanitySkillsLabels: SelectOptionPrograms[];
  traineeLabelPluralized?: string;
  guideLabelPluralized?: string;
}

export function SkillsMetricsTableContainer({
  programSlug,
  sanitySkillsLabels,
  traineeLabelPluralized,
  guideLabelPluralized,
}: Readonly<SkillsMetricsTableContainerProps>) {
  const { organizationSlug } = useAppContext();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const isLaptop = useMediaQuery(breakpoints.down('lg'));
  const today = new Date().toLocaleDateString();

  const {
    models: { skillsOfferedModel, skillsSoughtModel },
    filterValues,
    updateSkillsOffered,
    updateSkillsSought,
    updateFilterValues,
  } = useProgramIndividualTableContext();
  const { skillsOfferedDateRange, skillsSoughtDateRange } = filterValues;

  const { adminChartsSkillsOffered = [], isLoadingAdminChartsSkillsOffered } =
    useAdminChartsSkillsOffered({
      params: {
        programSlug,
        reportName: 'skills-offered',
        filterModel: skillsOfferedModel.filterModel,
      },
    });

  const { adminChartsSkillsSought = [], isLoadingAdminChartsSkillsSought } =
    useAdminChartsSkillsSought({
      params: {
        programSlug,
        reportName: 'skills-sought',
        filterModel: skillsSoughtModel.filterModel,
      },
    });

  const parsedSkillsSoughtRows = adminChartsSkillsSought.map((row, index) => {
    const { fieldSlug, id, ...rest } = row;

    const sanitySkills = sanitySkillsLabels?.find(
      (skill) => skill.id.current === fieldSlug,
    )?.label;

    function parsedSkillsSlugs() {
      if (!sanitySkills) {
        return fieldSlug;
      } else {
        return sanitySkills;
      }
    }

    return {
      fieldSlug: parsedSkillsSlugs(),
      id: `${id}-${index}`,
      ...rest,
    };
  });

  const skillsSoughtColumns: GridColDef[] = [
    {
      field: 'fieldSlug',
      headerName: 'Skills Sought',
      flex: 1,
      maxWidth: !isLaptop ? 350 : undefined,
      filterable: false,
    },
    { field: 'count', headerName: 'Count', minWidth: 120, filterable: false },
  ];

  const parsedSkillsOfferedRows = adminChartsSkillsOffered.map((row, index) => {
    const { fieldSlug, id, ...rest } = row;

    const sanitySkills = sanitySkillsLabels?.find(
      (skill) => skill.id.current === fieldSlug,
    )?.label;

    function parsedSkillsSlugs() {
      if (!sanitySkills) {
        return fieldSlug;
      } else {
        return sanitySkills;
      }
    }

    return {
      fieldSlug: parsedSkillsSlugs(),
      id: `${id}-${index}`,
      ...rest,
    };
  });

  const skillsOfferedColumns = [
    {
      field: 'fieldSlug',
      headerName: 'Skills Offered',
      flex: 1,
      maxWidth: !isLaptop ? 350 : undefined,
      filterable: false,
    },
    { field: 'count', headerName: 'Count', minWidth: 120, filterable: false },
  ];
  const handleDateChangeSkillsOffered = (value: IDateFilterValue) => {
    const to = value.to;
    const from = value.from;
    const dateValue =
      !to && !from
        ? undefined
        : {
            to: to?.toISOString(),
            from: from?.toISOString(),
          };
    const dateRange =
      !to && !from
        ? undefined
        : {
            to: to,
            from: from,
          };

    updateFilterValues({
      ...filterValues,
      skillsOfferedDateRange: dateRange,
    });
    updateSkillsOffered({
      filterModel: addFilterItem(skillsOfferedModel.filterModel, {
        field: 'createdAt',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
  };

  const skillsOfferedFilters: TFilter[] = [
    {
      filterType: 'date',
      id: '1-date',
      labelFrom: 'From',
      labelTo: 'To',
      onChange: handleDateChangeSkillsOffered,
      value: skillsOfferedDateRange,
      appBarProps: {
        anchorButton: {
          tooltip: 'Date Range',
          label: 'Date Range',
          value: parseDateFilterToString(skillsOfferedDateRange),
          id: 'date-picker-button',
          variant: 'text',
          color: 'info',
          startIcon: <FilterListIcon />,
        },
      },
    },
  ];
  const handleDateChangeSkillsSought = (value: IDateFilterValue) => {
    const to = value.to;
    const from = value.from;
    const dateValue =
      !to && !from
        ? undefined
        : {
            to: to?.toISOString(),
            from: from?.toISOString(),
          };
    const dateRange =
      !to && !from
        ? undefined
        : {
            to: to,
            from: from,
          };
    updateFilterValues({
      ...filterValues,
      skillsSoughtDateRange: dateRange,
    });
    updateSkillsSought({
      filterModel: addFilterItem(skillsSoughtModel.filterModel, {
        field: 'createdAt',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
  };
  const skillSoughtFilters: TFilter[] = [
    {
      filterType: 'date',
      id: '1-date',
      labelFrom: 'From',
      labelTo: 'To',
      onChange: handleDateChangeSkillsSought,
      value: skillsSoughtDateRange,
      appBarProps: {
        anchorButton: {
          tooltip: 'Date Range',
          label: 'Date Range',
          value: parseDateFilterToString(skillsSoughtDateRange),
          id: 'date-picker-button',
          variant: 'text',
          color: 'info',
          startIcon: <FilterListIcon />,
        },
      },
    },
  ];
  return (
    <Stack
      direction={isMobile || isLaptop ? 'column' : 'row'}
      justifyContent="space-between"
      display={'flex'}
      spacing={2}
      width="100%"
    >
      <SkillsMetricsTableView
        heading={`Skills Sought by ${traineeLabelPluralized}`}
        rows={parsedSkillsSoughtRows}
        columns={skillsSoughtColumns}
        loading={isLoadingAdminChartsSkillsSought}
        fileName={`${organizationSlug}-skills-sought-${today}`}
        filters={skillSoughtFilters}
      />
      <SkillsMetricsTableView
        heading={`Skills Offered by ${guideLabelPluralized}`}
        rows={parsedSkillsOfferedRows}
        columns={skillsOfferedColumns}
        loading={isLoadingAdminChartsSkillsOffered}
        fileName={`${organizationSlug}-skills-offered-${today}`}
        filters={skillsOfferedFilters}
      />
    </Stack>
  );
}
