import { ChartDownloaderDownload } from 'context/ChartDownloaderContext/types';
import { ChartDownloadStatus } from '../../views/ChartDownloadStatus';
import { useChartDownloader } from 'hooks/useChartDownloader';

export type ChartDownloadStatusContainerProps = {
  download: ChartDownloaderDownload;
};

export function ChartDownloadStatusContainer({
  download,
}: ChartDownloadStatusContainerProps) {
  const { cancelDownload } = useChartDownloader();

  const getStatus = () => {
    const { isComplete, didFail } = download.state;
    if (didFail) return 'error';
    if (isComplete) return 'success';
    return 'in-progress';
  };

  const filename = download.configuration.autoExport?.filename ?? 'n/a';

  return (
    <ChartDownloadStatus
      status={getStatus()}
      filename={filename}
      onDismiss={() => cancelDownload(download.id)}
    />
  );
}
