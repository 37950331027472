import {
  IProgramIndividualReducerState,
  TProgramIndividualReducerActions,
} from '../types';

export function programIndividualReducer(
  state: IProgramIndividualReducerState,
  action: TProgramIndividualReducerActions,
): IProgramIndividualReducerState {
  const models = state.models;
  switch (action.type) {
    case 'updateProgramGuideModel': {
      return {
        ...state,
        models: {
          ...models,
          programGuideModel: { ...models.programGuideModel, ...action.model },
        },
      };
    }
    case 'updateProgramTraineeModel': {
      return {
        ...state,
        models: {
          ...models,
          programTraineeModel: {
            ...models.programTraineeModel,
            ...action.model,
          },
        },
      };
    }
    case 'updateProgramSurveysPostRelationshipModel': {
      return {
        ...state,
        models: {
          ...models,
          programSurveysPostRelationshipModel: {
            ...models.programSurveysPostRelationshipModel,
            ...action.model,
          },
        },
      };
    }
    case 'updateProgramSurveysPostSessionModel': {
      return {
        ...state,
        models: {
          ...models,
          programSurveysPostSessionModel: {
            ...models.programSurveysPostSessionModel,
            ...action.model,
          },
        },
      };
    }
    case 'updateProgramActiveRelationshipsModel': {
      return {
        ...state,
        models: {
          ...models,
          programActiveRelationshipsModel: {
            ...models.programActiveRelationshipsModel,
            ...action.model,
          },
        },
      };
    }
    case 'updateProgramConcludedRelationshipsModel': {
      return {
        ...state,
        models: {
          ...models,
          programConcludedRelationshipsModel: {
            ...models.programConcludedRelationshipsModel,
            ...action.model,
          },
        },
      };
    }
    case 'updateProgramGoalsModel': {
      return {
        ...state,
        models: {
          ...models,
          programGoalsModel: { ...models.programGoalsModel, ...action.model },
        },
      };
    }
    case 'updateSessionsModel': {
      return {
        ...state,
        models: {
          ...models,
          sessionsModel: { ...models.sessionsModel, ...action.model },
        },
      };
    }
    case 'updateKpiModel': {
      return {
        ...state,
        models: {
          ...models,
          kpiModel: { ...models.kpiModel, ...action.model },
        },
      };
    }
    case 'updateFilterValues': {
      return {
        ...state,
        filterValues: {
          ...action.filterValue,
        },
      };
    }
    case 'updateSkillsSought': {
      return {
        ...state,
        models: {
          ...models,
          skillsSoughtModel: { ...models.skillsSoughtModel, ...action.model },
        },
      };
    }
    case 'updateSkillsOffered': {
      return {
        ...state,
        models: {
          ...models,
          skillsOfferedModel: { ...models.skillsOfferedModel, ...action.model },
        },
      };
    }
    default: {
      return state;
    }
  }
}
