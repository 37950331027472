import {
  IDateFilterValue,
  IMultiSelectChipFilterOption,
} from '@guider-global/ui';
import { ITableModels } from 'context/OverviewChartContext/types';
import { addFilterItem } from 'functions';
import { useState } from 'react';
import { v4 } from 'uuid';
import { MetricsFilterView } from 'views';

export interface IUserSearchFilterValues {
  firstName?: string;
  lastName?: string;
  email?: string;
  dateRange?: IDateFilterValue;
  countryOptions?: IMultiSelectChipFilterOption[];
  countryEnabled?: boolean;
  country?: IMultiSelectChipFilterOption[];
}

export interface UserSearchFilterContainerProps {
  userModel: ITableModels;
  updateUserModel: (model: Pick<ITableModels, 'filterModel'>) => void;
  countryOptions: IMultiSelectChipFilterOption[];
  countryEnabled: boolean;
}
export function UserSearchFilterContainer({
  updateUserModel,
  userModel,
  countryOptions,
  countryEnabled,
}: UserSearchFilterContainerProps) {
  const [{ firstName, lastName, email, dateRange, country }, setFilterValues] =
    useState<IUserSearchFilterValues>({
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      dateRange: undefined,
      countryOptions: undefined,
      countryEnabled: undefined,
    });
  const handleDateChange = (value: IDateFilterValue) => {
    const to = value.to;
    const from = value.from;
    const dateValue =
      !to && !from
        ? undefined
        : {
            to: to?.toISOString(),
            from: from?.toISOString(),
          };
    const dateRange =
      !to && !from
        ? undefined
        : {
            to: to,
            from: from,
          };
    setFilterValues({ dateRange });
    updateUserModel({
      filterModel: addFilterItem(userModel.filterModel, {
        field: 'createdAt',
        value: dateValue,
        operator: 'between',
        id: v4(),
      }),
    });
  };
  const handleEmailChange = (value?: string) => {
    updateUserModel({
      filterModel: addFilterItem(userModel.filterModel, {
        field: 'email',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
  };
  const handleFirstNameChange = (value?: string) => {
    updateUserModel({
      filterModel: addFilterItem(userModel.filterModel, {
        field: 'givenName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
  };
  const handleLastNameChange = (value?: string) => {
    updateUserModel({
      filterModel: addFilterItem(userModel.filterModel, {
        field: 'familyName',
        value,
        operator: 'contains',
        id: v4(),
      }),
    });
  };

  const handleCountryChange = (options?: IMultiSelectChipFilterOption[]) => {
    const isValues = options && options?.length > 0;
    const values = isValues
      ? options?.map((option) => option.id)?.join(',')
      : undefined;
    updateUserModel({
      filterModel: addFilterItem(userModel.filterModel, {
        field: 'country',
        value: values,
        operator: 'includes',
        id: v4(),
      }),
    });
  };

  return (
    <MetricsFilterView
      dateRange={dateRange}
      onDateChange={handleDateChange}
      email={email}
      onEmailChange={handleEmailChange}
      firstName={firstName}
      onFirstNameChange={handleFirstNameChange}
      lastName={lastName}
      onLastNameChange={handleLastNameChange}
      country={country}
      onCountryChange={handleCountryChange}
      countryOptions={countryOptions}
      updateFilterValues={(filter) => setFilterValues(filter)}
      countryEnabled={countryEnabled}
    />
  );
}
