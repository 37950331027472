import { firstLetterUppercase } from '@guider-global/front-end-utils';
import {
  IProfileProgramMembership,
  ProgramVariation,
} from '@guider-global/shared-types';
import { Button } from '@guider-global/ui';
import { PersonAdd } from '@mui/icons-material';
import { Box as MuiBox } from '@mui/material';
import { SuggestedMatchesContainer } from 'containers';
import { useAppContext } from 'context';
import { useAdminRelationships, useSnackbar } from 'hooks';
import { useAdminManualMatch } from 'hooks/admin/useAdminManualMatch';
import { ManualMatchModal } from 'modals';
import { useState } from 'react';

// types
export type CreateManualMatchParams = {
  role?: 'guide' | 'trainee';
  userProfileId?: string;
  manualMatchProfileId?: string;
  userEmail?: string;
  manualMatchEmail?: string;
  programSlug: string;
  programTypeSlug: string;
  programVariationTypeSlug: ProgramVariation;
};
export type manualMatchData = {
  programSlug: string;
  programName: string;
  programTypeSlug: string;
  programVariationTypeSlug: 'individual' | 'group';
  userProfileId: string;
  userDisplayName: string;
  userEmail: string;
};

export type MatchesContainerProps = {
  manualMatchData: manualMatchData;
  membership?: IProfileProgramMembership;
};

export function MatchesContainer({
  manualMatchData,
  membership,
}: MatchesContainerProps) {
  const {
    userEmail,
    userDisplayName,
    userProfileId,
    programName,
    programSlug,
    programTypeSlug,
    programVariationTypeSlug,
  } = manualMatchData;
  // Manual Match Alert

  const { setError, setSuccess } = useSnackbar();

  // Manual Match handlers
  const { organizationSlug, isRootDomain } = useAppContext();

  const { isLoadingAdminManualMatch, reqAdminManualMatch } =
    useAdminManualMatch({
      forceRefresh: false,
      getSilently: false,
    });

  const { reqAdminRelationships } = useAdminRelationships({
    forceRefresh: false,
    getSilently: false,
  });

  const isManualMatchRequestPending = isLoadingAdminManualMatch();

  // Modal Open
  const [isManualMatchModalOpen, setManualMatchModalOpen] =
    useState<boolean>(false);
  function handleManualMatchOpen() {
    setManualMatchModalOpen(true);
  }
  function handleManualMatchClose() {
    if (isManualMatchRequestPending) return;
    setManualMatchModalOpen(false);
  }
  const createManualMatch = async ({
    role,
    userEmail,
    manualMatchEmail,
    userProfileId,
    manualMatchProfileId,
    programSlug,
    programTypeSlug,
    programVariationTypeSlug,
  }: CreateManualMatchParams): Promise<{
    status: 'success' | 'error';
    message?: string;
  }> => {
    const data = {
      programSlug,
      programTypeSlug,
      programVariationTypeSlug,
      organizationSlug,
      ...(userEmail && manualMatchEmail
        ? {
            ...(role === 'trainee'
              ? {
                  traineeEmails: [userEmail],
                  guideEmails: [manualMatchEmail],
                }
              : {
                  guideEmails: [userEmail],
                  traineeEmails: [manualMatchEmail],
                }),
          }
        : {}),
      ...(userProfileId && manualMatchProfileId
        ? {
            traineeProfileIds: [userProfileId],
            guideProfileIds: [manualMatchProfileId],
          }
        : {}),
    };
    try {
      const response = await reqAdminManualMatch({
        method: 'POST',
        url: isRootDomain ? '/superadmin/manualMatch' : `/admin/manualMatch`,
        data,
      });
      console.log('Response from createManualMatch:', response);
      if (response.status === 'error') {
        return {
          status: 'error',
          message: firstLetterUppercase(response.message ?? 'Unknown error'),
        };
      }
      return {
        status: 'success',
        message: 'The manual match has been successfully created.',
      };
    } catch (error) {
      return { status: 'error', message: 'An unexpected error occurred.' };
    }
  };

  if (!membership) {
    return null;
  }

  const { role, otherRoleLabel } = membership;

  async function handleSuggestedMatches(manualMatchProfileId: string) {
    //
    const matchParams: CreateManualMatchParams = {
      userProfileId,
      programSlug,
      programTypeSlug,
      programVariationTypeSlug,
      manualMatchProfileId: manualMatchProfileId,
    };

    const result = await createManualMatch(matchParams);

    if (result.status === 'error') {
      setError({
        title: 'Manual Matching',
        message:
          'There was an issue with manual matching this users accounts, please try again',
      });
    } else {
      setSuccess({
        title: 'Manual Matching',
        message: 'The manual match has been successfully created.',
      });
      reqAdminRelationships({
        url: isRootDomain
          ? '/superadmin/relationships'
          : '/admin/relationships',
        params: {
          organizationSlug,
          profileId: userProfileId,
        },
      });
    }
  }

  async function handleCreateManualMatch(params: { manualMatchEmail: string }) {
    const additionalParams: Omit<CreateManualMatchParams, 'manualMatchEmail'> =
      {
        role: role,
        userEmail,
        programSlug,
        programTypeSlug,
        programVariationTypeSlug,
      };

    const result = await createManualMatch({
      manualMatchEmail: params.manualMatchEmail,

      ...additionalParams,
    });

    if (result.status === 'error') {
      setError({
        title: 'Manual Matching',
        message:
          'There was an issue with manual matching this users accounts, please try again',
      });
    } else {
      setSuccess({
        title: 'Manual Matching',
        message: 'The manual match has been successfully created.',
      });
      reqAdminRelationships({
        url: isRootDomain
          ? '/superadmin/relationships'
          : '/admin/relationships',
        params: {
          organizationSlug,
          profileId: userProfileId,
        },
      });
    }
  }

  if (programVariationTypeSlug === 'group') return <></>;
  return (
    <MuiBox pb={1}>
      <MuiBox
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 1,
        }}
      >
        {role === 'trainee' ? (
          <SuggestedMatchesContainer
            roleName={otherRoleLabel}
            programSlug={programSlug}
            onCreateManualMatch={handleSuggestedMatches}
            isManualMatchRequestPending={isManualMatchRequestPending}
          />
        ) : null}
        <Button
          variant="textWithIcon"
          label={`Add ${otherRoleLabel}`}
          startIcon={<PersonAdd />}
          onClick={handleManualMatchOpen}
        />
      </MuiBox>
      <ManualMatchModal
        show={isManualMatchModalOpen}
        busy={isManualMatchRequestPending}
        userDisplayName={userDisplayName}
        programName={programName}
        otherRoleLabel={otherRoleLabel}
        onClose={handleManualMatchClose}
        onCreateManualMatch={handleCreateManualMatch}
      />
    </MuiBox>
  );
}
export type CreateManualMatchResult =
  | {
      status: 'error';
      message: string;
    }
  | { status: 'success' };
