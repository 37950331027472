import { PropsWithChildren, createContext, useContext } from 'react';
import {
  initialProgramGroupFilterValues,
  initialProgramGroupTableModels,
} from './initials';
import { IProgramGroupTableModelsContext } from './types';
import { useProgramGroupActions } from './useProgramGroupActions';

export const initialProgramGroupTableModelsContext: IProgramGroupTableModelsContext =
  {
    models: initialProgramGroupTableModels,
    filterValues: initialProgramGroupFilterValues,
    updateGroupsModel: () => {},
    updateTraineeProfileModel: () => {},
    updateSessionsModel: () => {},
    updateKpiModel: () => {},
    updateOvertimeModel: () => {},
    updateFilterValues: () => {},
  };

export const ProgramGroupTableContext =
  createContext<IProgramGroupTableModelsContext>(
    initialProgramGroupTableModelsContext,
  );

export function useProgramGroupTableContext() {
  return useContext(ProgramGroupTableContext);
}

export function ProgramGroupTableProvider({
  children,
}: Readonly<PropsWithChildren>) {
  const contextValue = useProgramGroupActions();

  return (
    <ProgramGroupTableContext.Provider value={contextValue}>
      {children}
    </ProgramGroupTableContext.Provider>
  );
}
