import { GridLogicOperator, IFilterModel } from '@guider-global/shared-types';
import { IOverviewFilterValues, IOverviewTableModels } from '../types';
import { v4 } from 'uuid';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 0;
const DEFAULT_TO_DATE = new Date();
const DEFAULT_FROM_DATE = new Date();
DEFAULT_FROM_DATE.setDate(DEFAULT_TO_DATE.getDate() - 30);

const defaultFilterModel: IFilterModel = {
  items: [
    {
      field: 'createdAt',
      operator: 'between',
      value: undefined,
      id: v4(),
    },
  ],
  logicOperator: GridLogicOperator.And,
  quickFilterValues: [],
};
export const initialTableModels: IOverviewTableModels = {
  userModel: {
    paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
    sortModel: [],
    filterModel: defaultFilterModel,
  },
  guideMembershipsModel: {
    paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
    sortModel: [],
    filterModel: defaultFilterModel,
  },
  traineeMembershipsModel: {
    paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
    sortModel: [],
    filterModel: defaultFilterModel,
  },
  goalsModel: {
    paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
    sortModel: [],
    filterModel: defaultFilterModel,
  },
  goalsCumulativeModel: {
    filterModel: defaultFilterModel,
  },
  skillsCumulativeModel: {
    filterModel: defaultFilterModel,
  },
  kpiModel: {
    filterModel: defaultFilterModel,
  },
  overtimeModel: {
    filterModel: defaultFilterModel,
  },
};

export const initialOverviewFilterValues: IOverviewFilterValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  dateRange: undefined,
  country: undefined,
};
