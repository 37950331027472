import { ITableModels } from 'context/OverviewChartContext/types';
import { useCallback, useMemo, useReducer } from 'react';
import {
  initialProgramIndividualFilterValues,
  initialProgramIndividualTableModels,
} from '../initials';
import { programIndividualReducer } from '../programIndividualReducer';
import {
  IProgramIndividualFilterValues,
  IProgramIndividualTableModelsContext,
} from '../types';

export function useProgramIndividualActions(): IProgramIndividualTableModelsContext {
  const [state, dispatch] = useReducer(programIndividualReducer, {
    models: initialProgramIndividualTableModels,
    filterValues: initialProgramIndividualFilterValues,
  });

  const updateProgramGuideModel = useCallback(
    (model: Partial<ITableModels>) => {
      dispatch({ type: 'updateProgramGuideModel', model });
    },
    [],
  );
  const updateProgramTraineeModel = useCallback(
    (model: Partial<ITableModels>) => {
      dispatch({ type: 'updateProgramTraineeModel', model });
    },
    [],
  );
  const updateProgramActiveRelationshipsModel = useCallback(
    (model: Partial<ITableModels>) => {
      dispatch({ type: 'updateProgramActiveRelationshipsModel', model });
    },
    [],
  );
  const updateProgramConcludedRelationshipsModel = useCallback(
    (model: Partial<ITableModels>) => {
      dispatch({ type: 'updateProgramConcludedRelationshipsModel', model });
    },
    [],
  );
  const updateProgramGoalsModel = useCallback(
    (model: Partial<ITableModels>) => {
      dispatch({ type: 'updateProgramGoalsModel', model });
    },
    [],
  );
  const updateProgramSurveysPostSessionModel = useCallback(
    (model: Partial<ITableModels>) => {
      dispatch({ type: 'updateProgramSurveysPostSessionModel', model });
    },
    [],
  );
  const updateProgramSurveysPostRelationshipModel = useCallback(
    (model: Partial<ITableModels>) => {
      dispatch({ type: 'updateProgramSurveysPostRelationshipModel', model });
    },
    [],
  );

  const updateSessionsModel = useCallback((model: Partial<ITableModels>) => {
    dispatch({ type: 'updateSessionsModel', model });
  }, []);

  const updateKpiModel = useCallback(
    (model: Pick<ITableModels, 'filterModel'>) => {
      dispatch({ type: 'updateKpiModel', model });
    },
    [],
  );

  const updateFilterValues = useCallback(
    (value: Partial<IProgramIndividualFilterValues>) => {
      dispatch({ type: 'updateFilterValues', filterValue: value });
    },
    [],
  );
  const updateSkillsOffered = useCallback(
    (model: Pick<ITableModels, 'filterModel'>) => {
      dispatch({ type: 'updateSkillsOffered', model });
    },
    [],
  );

  const updateSkillsSought = useCallback(
    (model: Pick<ITableModels, 'filterModel'>) => {
      dispatch({ type: 'updateSkillsSought', model });
    },
    [],
  );

  return useMemo(
    () => ({
      models: state.models,
      filterValues: state.filterValues,
      updateProgramGuideModel,
      updateProgramTraineeModel,
      updateProgramActiveRelationshipsModel,
      updateProgramConcludedRelationshipsModel,
      updateProgramGoalsModel,
      updateSessionsModel,
      updateProgramSurveysPostSessionModel,
      updateProgramSurveysPostRelationshipModel,
      updateKpiModel,
      updateFilterValues,
      updateSkillsOffered,
      updateSkillsSought,
    }),
    [
      state.filterValues,
      state.models,
      updateFilterValues,
      updateKpiModel,
      updateProgramActiveRelationshipsModel,
      updateProgramConcludedRelationshipsModel,
      updateProgramGoalsModel,
      updateProgramGuideModel,
      updateProgramTraineeModel,
      updateProgramSurveysPostSessionModel,
      updateProgramSurveysPostRelationshipModel,
      updateSessionsModel,
      updateSkillsOffered,
      updateSkillsSought,
    ],
  );
}
