import { IFilterModel, IOvertimeChart } from '@guider-global/shared-types';
import { CheckboxItem, StackedGraph } from '@guider-global/ui';
import { useTheme } from '@mui/material';
import { getStackedGraphLegend } from 'functions';
import { useAdminOvertimeCharts, useChartExportOptions } from 'hooks';
import { useRef, useState } from 'react';

export interface StackedChartContainerProps {
  labels: {
    guidePluralized: string | undefined;
    traineePluralized: string | undefined;
  };
  programSlug?: string;
  filterModel?: IFilterModel;
  isLoading?: boolean;
  organizationSlug?: string;
}

export function StackedChartContainer({
  labels,
  programSlug,
  filterModel,
  isLoading = false,
  organizationSlug,
}: Readonly<StackedChartContainerProps>) {
  const { palette } = useTheme();
  const { guidePluralized, traineePluralized } = labels;
  const today = new Date().toLocaleDateString();

  const lineChartRef = useRef<HTMLDivElement | null>(null);

  const [menuItems, setMenuItems] = useState<CheckboxItem[]>([
    { heading: 'Cumulative', id: 'cumulative', checked: true },
  ]);

  const { adminOvertimeCharts, isLoadingAdminOvertimeCharts } =
    useAdminOvertimeCharts({
      params: {
        ...(programSlug && { programSlug }),
        filterModel,
      },
    });

  const overtimeChartsDataset = adminOvertimeCharts ?? [];

  const stackedGraphLegend = getStackedGraphLegend({
    guideLabelPluralized: guidePluralized,
    traineeLabelPluralized: traineePluralized,
    palette,
    isProgramSpecific: Boolean(programSlug),
  });

  const xAxisData = overtimeChartsDataset.map(
    (data) => new Date(data.createdAt!),
  );

  const isCumulative = Boolean(
    menuItems.find((item) => item.id === 'cumulative')?.checked,
  );

  const dataset = overtimeChartsDataset.reduce<IOvertimeChart[]>(
    (acc, chartDataset, currentIndex) => {
      if (isCumulative) {
        const previousItem = acc[currentIndex - 1];
        const currentOvertimeChartEntries = Object.entries(chartDataset) as [
          keyof IOvertimeChart,
          string | number | undefined,
        ][];
        const cumulativeDataSet =
          currentOvertimeChartEntries.reduce<IOvertimeChart>(
            (cumulativeDataSet, [key, value]) => {
              const currentValue = value ?? 0;
              if (key === 'createdAt' || typeof currentValue === 'string') {
                return {
                  [key]: currentValue,
                  ...cumulativeDataSet,
                };
              }
              const previousItemValue = previousItem ? previousItem[key] : 0;
              const cumulativeData = previousItemValue + currentValue;
              return {
                ...cumulativeDataSet,
                [key]: cumulativeData,
              };
            },
            {
              profilesCreated: 0,
              guideMembershipsCreated: 0,
              traineeMembershipsCreated: 0,
              activeRelationshipsCreated: 0,
              concludedRelationshipsCreated: 0,
              pastSessionsCreated: 0,
              sessionsCreated: 0,
              membershipsCreated: 0,
            },
          );

        return [...acc, cumulativeDataSet];
      }
      return [...acc, chartDataset];
    },
    [],
  );

  const handleMenuChange = (id: string, checked: boolean) => {
    setMenuItems((previousItems) => {
      const newItems = previousItems.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            checked,
          };
        }
        return item;
      });

      return newItems;
    });
  };

  const graphExportOptions = useChartExportOptions({
    reportPath: 'overtime',
    filename: `${organizationSlug}-charts-overtime-${today}`,
    filterModel: filterModel,
    chartRef: lineChartRef,
    headers: [
      { field: 'createdAt', headerName: 'Created at' },
      {
        field: 'activeRelationshipsCreated',
        headerName: 'Active relationships created',
      },
      {
        field: 'concludedRelationshipsCreated',
        headerName: 'Concluded relationships created',
      },
      {
        field: 'guideMembershipsCreated',
        headerName: 'Guide memberships created',
      },
      { field: 'membershipsCreated', headerName: 'Memberships created' },
      { field: 'profilesCreated', headerName: 'Profiles created' },
      { field: 'sessionsCreated', headerName: 'Sessions created' },
      {
        field: 'traineeMembershipsCreated',
        headerName: 'Trainee membership created',
      },
    ],
  });

  return (
    <StackedGraph
      ref={lineChartRef}
      exportDropdownButtons={graphExportOptions}
      legend={stackedGraphLegend}
      xAxis={[
        {
          id: 'signupDate',
          data: xAxisData,
          scaleType: 'time',
          valueFormatter: (xAxisData) => xAxisData.toLocaleDateString(),
        },
      ]}
      loading={
        isLoadingAdminOvertimeCharts ||
        isLoading ||
        adminOvertimeCharts === undefined
      }
      dataset={dataset}
      menuItems={menuItems}
      onMenuItemChange={handleMenuChange}
    />
  );
}
