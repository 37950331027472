import { useProfileImage } from '@guider-global/azure-storage-hooks';
import { AvatarBox, Chip, GridTable, Stack, Text } from '@guider-global/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { useAppContext } from 'context';
import { useAdminRelationships, useSnackbar } from 'hooks';
import { RelationshipActivateModal, RelationshipConcludeModal } from 'modals';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { RelationshipManageDropDownView } from 'views';

export interface IRelationshipsRow {
  id: string;
  name: string;
  auth0Picture?: string;
  blobPicture?: string;
  description?: string;
  status?: boolean;
  numberOfSessions: string;
  onClick?: () => void;
  relationshipId: string;
  title?: string;
}
export interface IRelationshipsHeaders {
  name: string;
  status: string;
  numberOfSessions: string;
  manage: string;
}
export type RelationshipsTableViewRowData = {
  id: string;
  relationshipId: string;
  avatarSrc?: string;
  displayName: string;
  createdAt: Date;
  status: 'active' | 'concluded';
  sessionCount: number;
};

export interface RelationshipsTableContainerProps {
  rows: IRelationshipsRow[];
  headers: IRelationshipsHeaders;
  userOrganizationSlug?: string;
}

export function RelationshipsTableContainer({
  rows,
  headers,
  userOrganizationSlug,
}: RelationshipsTableContainerProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [promptToConcludeId, setPromptToConcludeId] = useState<
    string | undefined
  >(undefined);
  const [promptToActivateId, setPromptToActivateId] = useState<
    string | undefined
  >(undefined);
  const { setError, setSuccess } = useSnackbar();

  const { reqAdminRelationships } = useAdminRelationships({
    forceRefresh: false,
    getSilently: false,
  });

  const { userProfileId } = useParams();

  const { getProfileImage } = useProfileImage();
  const { organizationSlug, isRootDomain } = useAppContext();

  const concludeRelationship = async (relationshipId: string) => {
    const requestUrl = isRootDomain
      ? `/superadmin/relationships/${relationshipId}`
      : `/admin/relationships/${relationshipId}`;

    const getConcludeRelationshipResults = await reqAdminRelationships({
      method: 'PATCH',
      url: requestUrl,
      data: {
        isConcluded: true,
      },
      params: {
        ...(!isRootDomain && { organizationSlug }),
      },
    });

    if (getConcludeRelationshipResults.status !== 'success') {
      console.error({ getConcludeRelationshipResults });
      setError({
        message:
          'There was an issue concluding this relationship, please try again',
        title: 'Conclude Relationship',
      });
    } else {
      setSuccess({
        message: 'The relationship has been concluded successfully',
        title: 'Conclude Relationship',
      });
    }

    reqAdminRelationships({
      url: isRootDomain ? '/superadmin/relationships' : '/admin/relationships',
      params: {
        organizationSlug: organizationSlug ?? userOrganizationSlug,
        profileId: userProfileId,
      },
    });
  };

  const activateRelationship = async (relationshipId: string) => {
    const requestUrl = isRootDomain
      ? `/superadmin/relationships/${relationshipId}`
      : `/admin/relationships/${relationshipId}`;

    const getActivateRelationshipResults = await reqAdminRelationships({
      method: 'PATCH',
      url: requestUrl,
      data: {
        isConcluded: false,
      },
      params: {
        ...(!isRootDomain && { organizationSlug }),
      },
    });

    if (getActivateRelationshipResults.status !== 'success') {
      console.error({ getActivateRelationshipResults });
      setError({
        message:
          'There was an issue activating this relationship, please try again',
        title: 'Activating Relationship',
      });
    } else {
      setSuccess({
        message: 'The relationship has been Activated successfully',
        title: 'Activating Relationship',
      });
    }

    reqAdminRelationships({
      url: isRootDomain ? '/superadmin/relationships' : '/admin/relationships',
      params: {
        organizationSlug: organizationSlug ?? userOrganizationSlug,
        profileId: userProfileId,
      },
    });
  };
  async function handleConcludeRelationship(relationshipId: string) {
    await concludeRelationship(relationshipId);
  }
  function onConcludeRelationship(relationshipId: string) {
    handleConcludeRelationship(relationshipId);
  }
  function onConcludeModalConfirm() {
    if (!promptToConcludeId) return;
    onConcludeRelationship(promptToConcludeId);
    setPromptToConcludeId(undefined);
  }

  async function handleActivateRelationship(relationshipId: string) {
    await activateRelationship(relationshipId);
  }
  function onActivateRelationship(relationshipId: string) {
    handleActivateRelationship(relationshipId);
  }
  function onActivateModalConfirm() {
    if (!promptToActivateId) return;
    onActivateRelationship(promptToActivateId);
    setPromptToActivateId(undefined);
  }

  function onActivateModalCancel() {
    setPromptToActivateId(undefined);
  }
  function onConcludeModalCancel() {
    setPromptToConcludeId(undefined);
  }

  return (
    <>
      {!isMobile && (
        <GridTable
          rowsPerPage={20}
          header={{
            cells: [
              {
                key: 'name',
                content: <Text text={headers.name} />,
                xs: 5.5,
                pl: 2,
              },
              {
                key: 'status',
                content: <Text text={headers.status} />,
                xs: 3,
                pl: '6px',
                pr: 2,
              },
              {
                key: 'next_session',
                content: <Text text={headers.numberOfSessions} />,
                xs: 2,
                pl: '6px',
                pr: 3,
              },
              {
                key: 'manage',
                content: <Text text={headers.manage} />,
                xs: 1,
                pl: 1,
              },
            ],
            sx: {
              backgroundColor: (theme) => theme.palette.background.default,
              pt: 2,
              pd: '6px',
              textAlign: 'left',
            },
          }}
          rows={rows.map((row) => {
            return {
              cells: [
                {
                  key: 'name',
                  content: (
                    <Stack direction="row" spacing={1}>
                      <AvatarBox
                        heading={row.name}
                        subtitles={[`Since: ${row.description}`]}
                        avatarSrc={getProfileImage({
                          profilePicture: row.blobPicture,
                          userPicture: row.auth0Picture,
                        })}
                        size={'small'}
                      />
                    </Stack>
                  ),
                  xs: 5.5,
                  pl: '11px',
                  onClick: row.onClick,
                },
                {
                  key: 'status',
                  content: (
                    <Text
                      text={
                        row.status ? (
                          <Chip
                            label="Concluded"
                            color="dark"
                            variant="outlined"
                          />
                        ) : (
                          <Chip label="Active" color="success" />
                        )
                      }
                    />
                  ),
                  xs: 3,
                  pl: '6px',
                },
                {
                  key: 'session_number',
                  content: (
                    <Text text={row.numberOfSessions.toString() ?? '0'} />
                  ),
                  xs: 2,
                  pl: '6px',
                },
                {
                  key: 'manage',
                  content: (
                    <RelationshipManageDropDownView
                      status={row.status}
                      onConcludeRelationship={() => {
                        setPromptToConcludeId(row.relationshipId);
                      }}
                      onActivateRelationship={() => {
                        setPromptToActivateId(row.relationshipId);
                      }}
                    />
                  ),
                  xs: 1,
                  pl: 3,
                },
              ],
            };
          })}
          rowProps={{
            sx: {
              py: 1.5,
              color: (theme) => theme.palette.text.secondary,
              borderTop: (theme) => `1px solid ${theme.palette.divider}`,

              cursor: 'pointer',
              textAlign: 'left',
              boxSizing: 'auto',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.paper.hover.light,
              },
            },
          }}
          sx={{
            border: (theme) =>
              `1px solid ${theme.palette.paper.border.primary}`,
            borderRadius: '8px',
            boxShadow: 'none',
            margin: 0,
          }}
          showNavigator={false}
        />
      )}
      {isMobile && (
        <GridTable
          rowsPerPage={20}
          header={{
            cells: [
              {
                key: 'mentee_mentor',
                content: <Text text={headers.name} />,
                xs: 9,
                pl: 1,
              },
              {
                key: 'manage',
                content: <Text text="Manage" />,
                xs: 2,
              },
            ],
            sx: {
              backgroundColor: (theme) => theme.palette.background.default,
              textAlign: 'left',
            },
          }}
          rows={rows.map((row) => {
            return {
              cells: [
                {
                  key: 'mentee_mentor',
                  content: (
                    <AvatarBox
                      heading={row.name}
                      size="small"
                      subtitles={[
                        row.status ? 'Concluded' : 'Active',
                        `${row.numberOfSessions} Sessions Held`,
                      ]}
                      avatarSrc={getProfileImage({
                        profilePicture: row.blobPicture,
                        userPicture: row.auth0Picture,
                      })}
                      sx={{ alignItems: 'center' }}
                    />
                  ),
                  xs: 9,
                  pl: 1,
                },
                {
                  key: 'manage',
                  content: (
                    <RelationshipManageDropDownView
                      status={row.status}
                      onConcludeRelationship={() => {
                        setPromptToConcludeId(row.relationshipId);
                      }}
                      onActivateRelationship={() => {
                        setPromptToActivateId(row.relationshipId);
                      }}
                    />
                  ),
                  xs: 2,
                  pl: 1.5,
                },
              ],
            };
          })}
          rowProps={{
            sx: {
              py: 1.5,
              color: (theme) => theme.palette.text.secondary,
              borderTop: (theme) => `1px solid ${theme.palette.divider}`,
              cursor: 'pointer',
              textAlign: 'left',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.paper.hover.light,
              },
            },
          }}
          sx={{
            border: (theme) =>
              `1px solid ${theme.palette.paper.border.primary}`,
            borderRadius: '8px',
            boxShadow: 'none',
            margin: 0,
          }}
          showNavigator={false}
        />
      )}
      <RelationshipConcludeModal
        isOpen={promptToConcludeId !== undefined}
        onConfirm={onConcludeModalConfirm}
        onCancel={onConcludeModalCancel}
      />
      <RelationshipActivateModal
        isOpen={promptToActivateId !== undefined}
        onConfirm={onActivateModalConfirm}
        onCancel={onActivateModalCancel}
      />
    </>
  );
}
