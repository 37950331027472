import { useCallback, useMemo, useReducer } from 'react';
import { initialOverviewFilterValues, initialTableModels } from '../intials';
import { overviewChartReducer } from '../overviewChartReducer';
import {
  IOverviewFilterValues,
  IOverviewTableModelsContext,
  ITableModels,
} from '../types';

export function useOverviewChartActions(): IOverviewTableModelsContext {
  const [state, dispatch] = useReducer(overviewChartReducer, {
    models: initialTableModels,
    filterValues: initialOverviewFilterValues,
  });

  const updateUserModel = useCallback((model: Partial<ITableModels>) => {
    dispatch({ type: 'updateUserModel', model });
  }, []);
  const updateGuideMembershipsModel = useCallback(
    (model: Partial<ITableModels>) => {
      dispatch({ type: 'updateGuideMembershipsModel', model });
    },
    [],
  );
  const updateTraineeMembershipsModel = useCallback(
    (model: Partial<ITableModels>) => {
      dispatch({ type: 'updateTraineeMembershipsModel', model });
    },
    [],
  );
  const updateGoalsModel = useCallback((model: Partial<ITableModels>) => {
    dispatch({ type: 'updateGoalsModel', model });
  }, []);

  const updateGoalsCumulativeModel = useCallback(
    (model: Pick<ITableModels, 'filterModel'>) => {
      dispatch({ type: 'updateGoalsCumulativeModel', model });
    },
    [],
  );

  const updateSkillsCumulativeModel = useCallback(
    (model: Pick<ITableModels, 'filterModel'>) => {
      dispatch({ type: 'updateSkillsCumulativeModel', model });
    },
    [],
  );

  const updateKPIModel = useCallback(
    (model: Pick<ITableModels, 'filterModel'>) => {
      dispatch({ type: 'updateKPIModel', model });
    },
    [],
  );
  const updateOvertimeModel = useCallback(
    (model: Pick<ITableModels, 'filterModel'>) => {
      dispatch({ type: 'updateOvertimeModel', model });
    },
    [],
  );

  const updateFilterValues = useCallback(
    (value: Partial<IOverviewFilterValues>) => {
      dispatch({ type: 'updateFilterValues', filterValue: value });
    },
    [],
  );

  return useMemo(
    () => ({
      models: state.models,
      filterValues: state.filterValues,
      updateUserModel,
      updateGuideMembershipsModel,
      updateTraineeMembershipsModel,
      updateGoalsModel,
      updateGoalsCumulativeModel,
      updateSkillsCumulativeModel,
      updateKPIModel,
      updateOvertimeModel,
      updateFilterValues,
    }),
    [
      state,
      updateGoalsModel,
      updateGoalsCumulativeModel,
      updateSkillsCumulativeModel,
      updateGuideMembershipsModel,
      updateKPIModel,
      updateOvertimeModel,
      updateTraineeMembershipsModel,
      updateUserModel,
      updateFilterValues,
    ],
  );
}
