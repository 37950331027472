import {
  ChartDownloaderAction,
  ChartDownloaderDownloadConfiguration,
  ChartDownloaderDownloadState,
  ChartDownloaderState,
} from '../types';

function updateDownloadState(
  state: ChartDownloaderState,
  id: string,
  stateUpdater: (
    downloadState: ChartDownloaderDownloadState,
    downloadConfiguration: ChartDownloaderDownloadConfiguration,
  ) => ChartDownloaderDownloadState,
): ChartDownloaderState {
  const download = state.downloads.find((download) => download.id === id);
  if (!download) return state;
  return {
    ...state,
    downloads: [
      ...state.downloads.filter((download) => download.id !== id),
      {
        ...download,
        state: stateUpdater(download.state, download.configuration),
      },
    ],
  };
}

export function ChartDownloaderReducer(
  state: ChartDownloaderState,
  action: ChartDownloaderAction,
): ChartDownloaderState {
  switch (action.type) {
    case 'create': {
      return {
        ...state,
        downloads: [...state.downloads, action.download],
      };
    }
    case 'start': {
      return updateDownloadState(state, action.id, (downloadState) => ({
        ...downloadState,
        shouldRequest: true,
      }));
    }
    case 'pause': {
      return updateDownloadState(state, action.id, (downloadState) => ({
        ...downloadState,
        shouldRequest: false,
      }));
    }
    case 'cancel': {
      return updateDownloadState(state, action.id, (downloadState) => ({
        ...downloadState,
        isCancelled: true,
      }));
    }
    case 'begin-request': {
      return updateDownloadState(state, action.id, (downloadState) => ({
        ...downloadState,
        isRequesting: true,
      }));
    }
    case 'complete-request-success': {
      const { newRows } = action;
      const newRowCount = newRows.length;
      return updateDownloadState(state, action.id, (downloadState) => ({
        ...downloadState,
        isRequesting: false,
        isComplete: newRowCount === 0,
        pageIndex: downloadState.pageIndex + 1,
        accumulatedRows: [...downloadState.accumulatedRows, ...newRows],
        rowCount: downloadState.rowCount + newRowCount,
      }));
    }
    case 'complete-request-error': {
      return updateDownloadState(state, action.id, (downloadState) => ({
        ...downloadState,
        isRequesting: false,
        didFail: true,
      }));
    }
    case 'export': {
      return updateDownloadState(state, action.id, (downloadState) => ({
        ...downloadState,
        didExport: true,
      }));
    }
    default: {
      return state;
    }
  }
}
