import { Outlet } from 'react-router-dom';
import { GridCentered, theme, ThemeWrapper } from '@guider-global/ui';
import { authPalette } from '@guider-global/ui';

type AuthThemeContainerProps = {
  children?: JSX.Element;
};

export function AuthThemeContainer({ children }: AuthThemeContainerProps) {
  console.log('AUTH THEME');

  return (
    <ThemeWrapper customTheme={theme.authTheme}>
      <GridCentered
        justifyContent="flex-start"
        sx={{
          paddingTop: '150px',
          backgroundColor: authPalette.default.background.primary,
        }}
      >
        {children ?? <Outlet />}
      </GridCentered>
    </ThemeWrapper>
  );
}
