import { useAuth } from '@guider-global/auth-hooks';
import {
  exportCSVFile,
  exportJSONFile,
  exportPNGFile,
} from '@guider-global/front-end-utils';
import { useAxios } from '@guider-global/redux-axios-hooks';
import { useAppContext } from 'context/AppContext';
import { useCallback, useEffect, useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ChartDownloaderReducer } from '../ChartDownloaderReducer';
import {
  ChartDownloaderContextPayload,
  ChartDownloaderDownload,
  ChartDownloaderDownloadConfiguration,
} from '../types';

export function useChartDownloaderEngine(): ChartDownloaderContextPayload {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { accessToken, getAccessToken } = useAuth({});
  const { requestCallback } = useAxios({
    accessToken,
    onExpiredAccessToken: getAccessToken,
    waitForAuthentication: true,
  });

  // Reducer
  const [state, dispatch] = useReducer(ChartDownloaderReducer, {
    downloads: [],
  });

  const createDownload = useCallback(
    (configuration: Partial<ChartDownloaderDownloadConfiguration>) => {
      const id = uuidv4();
      const download: ChartDownloaderDownload = {
        id,
        configuration: {
          reportName: configuration.reportName ?? undefined,
          reportPath: configuration.reportPath,
          programSlug: configuration.programSlug,
          pageLimit: configuration.pageLimit ?? 1000,
          sortModel: configuration.sortModel,
          filterModel: configuration.filterModel,
          onComplete: configuration.onComplete ?? (() => {}),
          autoExport: configuration.autoExport,
        },
        state: {
          shouldRequest: false,
          isRequesting: false,
          isComplete: false,
          isCancelled: false,
          didFail: false,
          didExport: false,
          pageIndex: 1,
          accumulatedRows: [],
          rowCount: 0,
        },
      };
      dispatch({
        type: 'create',
        download,
      });
      return id;
    },
    [],
  );
  const startDownload = useCallback((id: string) => {
    dispatch({
      type: 'start',
      id,
    });
  }, []);
  const pauseDownload = useCallback((id: string) => {
    dispatch({
      type: 'pause',
      id,
    });
  }, []);
  const cancelDownload = useCallback((id: string) => {
    dispatch({
      type: 'cancel',
      id,
    });
  }, []);

  const getDownloadConfiguration = (id: string) =>
    state.downloads.find((download) => download.id === id)?.configuration;

  const getDownloadState = (id: string) =>
    state.downloads.find((download) => download.id === id)?.state;

  // Perform download requests
  const baseUrl = isRootDomain ? 'superadmin/charts' : 'admin/charts';
  const shouldAppendOrganizationSlug =
    isRootDomain && organizationSlug !== 'guider';

  useEffect(() => {
    state.downloads.forEach((download) => {
      const { shouldRequest, isRequesting, isComplete, isCancelled, didFail } =
        download.state;

      if (!shouldRequest) return;
      if (isRequesting) return;
      if (isComplete) return;
      if (isCancelled) return;
      if (didFail) return;

      const handleRequest = async () => {
        dispatch({
          type: 'begin-request',
          id: download.id,
        });

        const {
          reportPath,
          reportName,
          pageLimit,
          sortModel,
          filterModel,
          programSlug,
        } = download.configuration;
        const { pageIndex } = download.state;
        const requestUrl = reportPath ? `${baseUrl}/${reportPath}` : baseUrl;
        const result = await requestCallback<Record<string, unknown>>({
          method: 'GET',
          url: requestUrl,
          params: {
            reportName,
            programSlug,
            page: pageIndex,
            pageLimit,
            sortModel,
            filterModel,
            ...(shouldAppendOrganizationSlug && { organizationSlug }),
          },
        });

        if (result.status !== 200) {
          dispatch({
            type: 'complete-request-error',
            id: download.id,
          });
          return;
        }

        dispatch({
          type: 'complete-request-success',
          id: download.id,
          newRows: result.data.data,
        });
      };
      handleRequest();
    });
  }, [requestCallback, baseUrl, state.downloads]);

  // Perform download auto-exports
  useEffect(() => {
    state.downloads.forEach((download) => {
      const { autoExport } = download.configuration;
      const canExport = autoExport !== undefined;
      if (!canExport) return;
      const shouldExport = download.state.didExport === false;
      if (!shouldExport) return;
      const readyToExport = download.state.isComplete === true;
      if (!readyToExport) return;

      const { filename, filetype, headers, chartRef = null } = autoExport;
      const { accumulatedRows } = download.state;
      dispatch({
        type: 'export',
        id: download.id,
      });
      if (filetype === 'csv') {
        exportCSVFile({
          headers,
          rows: accumulatedRows,
          fileName: filename,
        });
        return;
      }
      if (filetype === 'json') {
        exportJSONFile({
          keys: headers,
          data: accumulatedRows,
          fileName: filename,
        });
      }
      if (filetype === 'png') {
        exportPNGFile({
          chartRef,
          fileName: filename,
        });
      }
    });
  }, [state.downloads]);

  return {
    createDownload,
    startDownload,
    pauseDownload,
    cancelDownload,
    getDownloadConfiguration,
    getDownloadState,
    downloads: state.downloads,
  };
}
