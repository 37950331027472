import { PropsWithChildren, createContext, useContext } from 'react';
import { initialOverviewFilterValues, initialTableModels } from './intials';
import { IOverviewTableModelsContext } from './types';
import { useOverviewChartActions } from './useOverviewChartActions';

export const initialTableModelsContext: IOverviewTableModelsContext = {
  models: initialTableModels,
  filterValues: initialOverviewFilterValues,
  updateUserModel: () => {},
  updateGuideMembershipsModel: () => {},
  updateTraineeMembershipsModel: () => {},
  updateGoalsModel: () => {},
  updateGoalsCumulativeModel: () => {},
  updateSkillsCumulativeModel: () => {},
  updateKPIModel: () => {},
  updateOvertimeModel: () => {},
  updateFilterValues: () => {},
};

export const OverviewTableContext = createContext<IOverviewTableModelsContext>(
  initialTableModelsContext,
);

export function useOverviewTableContext() {
  return useContext(OverviewTableContext);
}

export function OverMetricsTableModelProvider({
  children,
}: Readonly<PropsWithChildren>) {
  const contextValue = useOverviewChartActions();

  return (
    <OverviewTableContext.Provider value={contextValue}>
      {children}
    </OverviewTableContext.Provider>
  );
}
