import {
  GridLogicOperator,
  IFilterItem,
  IFilterModel,
} from '@guider-global/shared-types';
import {
  IProgramIndividualFilterValues,
  IProgramIndividualTableModels,
} from '../types';
import { v4 } from 'uuid';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 0;
const DEFAULT_TO_DATE = new Date();
const DEFAULT_FROM_DATE = new Date();
DEFAULT_FROM_DATE.setDate(DEFAULT_TO_DATE.getDate() - 30);

const defaultProgramIndividualFilterModel = (field: string): IFilterModel => {
  const model: IFilterModel = {
    items: [
      {
        field: field,
        operator: 'between',
        value: undefined,
        id: `default-${v4()}`,
      } as IFilterItem,
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  };
  return model;
};
export const initialProgramIndividualTableModels: IProgramIndividualTableModels =
  {
    programGuideModel: {
      paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
      sortModel: [],
      filterModel: defaultProgramIndividualFilterModel('joinedProgramOn'),
    },
    programTraineeModel: {
      paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
      sortModel: [],
      filterModel: defaultProgramIndividualFilterModel('joinedProgramOn'),
    },
    programActiveRelationshipsModel: {
      paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
      sortModel: [],
      filterModel: defaultProgramIndividualFilterModel('createdAt'),
    },
    programConcludedRelationshipsModel: {
      paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
      sortModel: [],
      filterModel: defaultProgramIndividualFilterModel('createdAt'),
    },
    programGoalsModel: {
      paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
      sortModel: [],
      filterModel: defaultProgramIndividualFilterModel('createdAt'),
    },
    sessionsModel: {
      paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
      sortModel: [],
      filterModel: defaultProgramIndividualFilterModel(''),
    },
    programSurveysPostRelationshipModel: {
      paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
      sortModel: [],
      filterModel: defaultProgramIndividualFilterModel(''),
    },
    programSurveysPostSessionModel: {
      paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
      sortModel: [],
      filterModel: defaultProgramIndividualFilterModel(''),
    },
    kpiModel: {
      filterModel: defaultProgramIndividualFilterModel('createdAt'),
    },
    skillsSoughtModel: {
      filterModel: defaultProgramIndividualFilterModel('createdAt'),
    },
    skillsOfferedModel: {
      filterModel: defaultProgramIndividualFilterModel('createdAt'),
    },
  };

export const initialProgramIndividualFilterValues: IProgramIndividualFilterValues =
  {
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    dateRange: undefined,
    skillsOfferedDateRange: undefined,
    skillsSoughtDateRange: undefined,
    country: undefined,
  };
