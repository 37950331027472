import { ConfirmationModal } from '@guider-global/ui';
import DeleteIcon from '@mui/icons-material/Delete';

export type DeleteUserModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  buttons: {
    loading: boolean;
  };
};

export function DeleteUserModal({
  isOpen,
  onConfirm,
  onCancel,
  buttons,
}: DeleteUserModalProps) {
  return (
    <ConfirmationModal
      icon={<DeleteIcon />}
      open={isOpen}
      onClose={onCancel}
      color="error"
      title={'Delete User'}
      description="This will delete profiles and meta data linked to this account"
      buttons={[
        {
          label: 'Cancel',
          variant: 'outlined',
          color: 'info',
          onClick: onCancel,
          loading: buttons.loading,
        },
        {
          label: 'Confirm',
          variant: 'contained',
          onClick: onConfirm,
          loading: buttons.loading,
        },
      ]}
    />
  );
}
