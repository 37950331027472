import {
  GridLogicOperator,
  IFilterItem,
  IFilterModel,
} from '@guider-global/shared-types';
import { IProgramGroupFilterValues, IProgramGroupTableModels } from '../types';
import { v4 } from 'uuid';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 0;
const DEFAULT_TO_DATE = new Date();
const DEFAULT_FROM_DATE = new Date();
DEFAULT_FROM_DATE.setDate(DEFAULT_TO_DATE.getDate() - 30);

const defaultProgramGroupFilterModel = (field: string): IFilterModel => {
  const model: IFilterModel = {
    items: [
      {
        field: field,
        operator: 'between',
        value: undefined,
        id: `default-${v4()}`,
      } as IFilterItem,
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  };
  return model;
};
export const initialProgramGroupTableModels: IProgramGroupTableModels = {
  groupsModel: {
    paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
    sortModel: [],
    filterModel: defaultProgramGroupFilterModel('createdAt'),
  },
  traineeProfileModel: {
    paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
    sortModel: [],
    filterModel: defaultProgramGroupFilterModel('createdAt'),
  },
  sessionsModel: {
    paginationModel: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
    sortModel: [],
    filterModel: defaultProgramGroupFilterModel('sessionStart'),
  },
  kpiModel: {
    filterModel: defaultProgramGroupFilterModel('createdAt'),
  },
  overtimeModel: {
    filterModel: defaultProgramGroupFilterModel('createdAt'),
  },
};

export const initialProgramGroupFilterValues: IProgramGroupFilterValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  dateRange: undefined,
  country: undefined,
};
