import { ExportCSVHeaders } from '@guider-global/front-end-utils';
import { GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { useChartDownloader } from 'hooks/useChartDownloader';
import { useCallback, useMemo } from 'react';
import {
  MongoMetricsView,
  MongoMetricsViewProps,
} from 'views/MongoMetricsView';

export interface MongoMetricsContainerProps {
  metricsTableProps: MongoMetricsViewProps['metricsTableProps'];
  reportName?: string;
  reportPath?: string;
  filename: string;
  programSlug?: string;
}

export function MongoMetricsContainer({
  metricsTableProps,
  reportName,
  reportPath,
  programSlug,
  filename,
}: Readonly<MongoMetricsContainerProps>) {
  // Chart Downloader
  const chartDownloader = useChartDownloader();
  const { createDownload, startDownload } = chartDownloader;
  const { sortModel, filterModel, columns } = metricsTableProps;

  //
  const initiateDownload = useCallback(
    (filetype: 'csv' | 'json') => {
      const downloadId = createDownload({
        reportPath,
        reportName,
        programSlug,
        pageLimit: 5000,
        sortModel,
        filterModel,
        autoExport: {
          headers: columns as ExportCSVHeaders[],
          filename,
          filetype,
        },
      });
      startDownload(downloadId);
    },
    [
      createDownload,
      reportPath,
      reportName,
      programSlug,
      sortModel,
      filterModel,
      columns,
      filename,
      startDownload,
    ],
  );
  const initiateCsvDownload = useCallback(() => {
    initiateDownload('csv');
  }, [initiateDownload]);
  const initiateJsonDownload = useCallback(() => {
    initiateDownload('json');
  }, [initiateDownload]);

  // Export dropdowns
  const exportDropdownButtons = useMemo(
    () => [
      {
        key: 'csv',
        label: 'Download as CSV',
        onClick: initiateCsvDownload,
      },
      {
        key: 'json',
        label: 'Download as JSON',
        onClick: initiateJsonDownload,
      },
    ],
    [initiateCsvDownload, initiateJsonDownload],
  );

  return (
    <MongoMetricsView
      metricsTableProps={metricsTableProps}
      exportDropdownButtons={exportDropdownButtons}
    />
  );
}
