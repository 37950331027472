import {
  IProgramGroupReducerState,
  TProgramGroupReducerActions,
} from '../types';

export function programGroupReducer(
  state: IProgramGroupReducerState,
  action: TProgramGroupReducerActions,
): IProgramGroupReducerState {
  const models = state.models;
  switch (action.type) {
    case 'updateGroupsModel': {
      return {
        ...state,
        models: {
          ...models,
          groupsModel: { ...models.groupsModel, ...action.model },
        },
      };
    }
    case 'updateTraineeProfileModel': {
      return {
        ...state,
        models: {
          ...models,
          traineeProfileModel: {
            ...models.traineeProfileModel,
            ...action.model,
          },
        },
      };
    }
    case 'updateSessionsModel': {
      return {
        ...state,
        models: {
          ...models,
          sessionsModel: {
            ...models.sessionsModel,
            ...action.model,
          },
        },
      };
    }

    case 'updateKpiModel': {
      return {
        ...state,
        models: {
          ...models,
          kpiModel: { ...models.kpiModel, ...action.model },
        },
      };
    }

    case 'updateOvertimeModel': {
      return {
        ...state,
        models: {
          ...models,
          kpiModel: { ...models.kpiModel, ...action.model },
        },
      };
    }

    case 'updateFilterValues': {
      return {
        ...state,
        filterValues: {
          ...action.filterValue,
        },
      };
    }

    default: {
      return state;
    }
  }
}
