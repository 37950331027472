import { ITableModels } from 'context/OverviewChartContext/types';
import { useCallback, useMemo, useReducer } from 'react';
import {
  initialProgramGroupFilterValues,
  initialProgramGroupTableModels,
} from '../initials';
import { programGroupReducer } from '../programGroupReducer';
import {
  IProgramGroupFilterValues,
  IProgramGroupTableModelsContext,
} from '../types';

export function useProgramGroupActions(): IProgramGroupTableModelsContext {
  const [state, dispatch] = useReducer(programGroupReducer, {
    models: initialProgramGroupTableModels,
    filterValues: initialProgramGroupFilterValues,
  });

  const updateGroupsModel = useCallback((model: Partial<ITableModels>) => {
    dispatch({ type: 'updateGroupsModel', model });
  }, []);
  const updateTraineeProfileModel = useCallback(
    (model: Partial<ITableModels>) => {
      dispatch({ type: 'updateTraineeProfileModel', model });
    },
    [],
  );

  const updateSessionsModel = useCallback((model: Partial<ITableModels>) => {
    dispatch({ type: 'updateSessionsModel', model });
  }, []);

  const updateKpiModel = useCallback(
    (model: Pick<ITableModels, 'filterModel'>) => {
      dispatch({ type: 'updateKpiModel', model });
    },
    [],
  );

  const updateOvertimeModel = useCallback(
    (model: Pick<ITableModels, 'filterModel'>) => {
      dispatch({ type: 'updateOvertimeModel', model });
    },
    [],
  );

  const updateFilterValues = useCallback(
    (value: Partial<IProgramGroupFilterValues>) => {
      dispatch({ type: 'updateFilterValues', filterValue: value });
    },
    [],
  );

  return useMemo(
    () => ({
      models: state.models,
      filterValues: state.filterValues,
      updateGroupsModel,
      updateTraineeProfileModel,
      updateSessionsModel,
      updateKpiModel,
      updateOvertimeModel,
      updateFilterValues,
    }),
    [
      state.filterValues,
      state.models,
      updateFilterValues,
      updateGroupsModel,
      updateKpiModel,
      updateOvertimeModel,
      updateSessionsModel,
      updateTraineeProfileModel,
    ],
  );
}
