import { IOverviewChartReducerState, TOverviewReducerActions } from '../types';

export function overviewChartReducer(
  state: IOverviewChartReducerState,
  action: TOverviewReducerActions,
): IOverviewChartReducerState {
  const models = state.models;
  switch (action.type) {
    case 'updateUserModel': {
      return {
        ...state,
        models: {
          ...models,
          userModel: { ...models.userModel, ...action.model },
        },
      };
    }
    case 'updateGuideMembershipsModel': {
      return {
        ...state,
        models: {
          ...models,
          guideMembershipsModel: {
            ...models.guideMembershipsModel,
            ...action.model,
          },
        },
      };
    }
    case 'updateTraineeMembershipsModel': {
      return {
        ...state,
        models: {
          ...models,
          traineeMembershipsModel: {
            ...models.traineeMembershipsModel,
            ...action.model,
          },
        },
      };
    }
    case 'updateGoalsModel': {
      return {
        ...state,
        models: {
          ...models,
          goalsModel: { ...models.goalsModel, ...action.model },
        },
      };
    }
    case 'updateKPIModel': {
      return {
        ...state,
        models: {
          ...models,
          kpiModel: { ...models.kpiModel, ...action.model },
        },
      };
    }
    case 'updateOvertimeModel': {
      return {
        ...state,
        models: {
          ...models,
          overtimeModel: { ...models.overtimeModel, ...action.model },
        },
      };
    }
    case 'updateGoalsCumulativeModel': {
      return {
        ...state,
        models: {
          ...models,
          goalsCumulativeModel: {
            ...models.goalsCumulativeModel,
            ...action.model,
          },
        },
      };
    }
    case 'updateSkillsCumulativeModel': {
      return {
        ...state,
        models: {
          ...models,
          skillsCumulativeModel: {
            ...models.skillsCumulativeModel,
            ...action.model,
          },
        },
      };
    }
    case 'updateFilterValues': {
      return {
        ...state,
        filterValues: {
          ...action.filterValue,
        },
      };
    }
    default: {
      return state;
    }
  }
}
