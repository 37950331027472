import { PropsWithChildren, createContext, useContext } from 'react';
import {
  initialProgramIndividualFilterValues,
  initialProgramIndividualTableModels,
} from './initials';
import { IProgramIndividualTableModelsContext } from './types';
import { useProgramIndividualActions } from './useProgramIndividualActions';

export const initialProgramIndividualTableModelsContext: IProgramIndividualTableModelsContext =
  {
    models: initialProgramIndividualTableModels,
    filterValues: initialProgramIndividualFilterValues,
    updateProgramGuideModel: () => {},
    updateProgramTraineeModel: () => {},
    updateProgramActiveRelationshipsModel: () => {},
    updateProgramConcludedRelationshipsModel: () => {},
    updateProgramGoalsModel: () => {},
    updateSessionsModel: () => {},
    updateKpiModel: () => {},
    updateFilterValues: () => {},
    updateSkillsOffered: () => {},
    updateSkillsSought: () => {},
    updateProgramSurveysPostRelationshipModel: () => {},
    updateProgramSurveysPostSessionModel: () => {},
  };

export const ProgramIndividualTableContext =
  createContext<IProgramIndividualTableModelsContext>(
    initialProgramIndividualTableModelsContext,
  );

export function useProgramIndividualTableContext() {
  return useContext(ProgramIndividualTableContext);
}

export function ProgramIndividualTableProvider({
  children,
}: Readonly<PropsWithChildren>) {
  const contextValue = useProgramIndividualActions();

  return (
    <ProgramIndividualTableContext.Provider value={contextValue}>
      {children}
    </ProgramIndividualTableContext.Provider>
  );
}
