import {
  getFormattedProgramFields,
  getFormattedSkills,
} from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useBaseLanguage,
} from '@guider-global/sanity-hooks';
import { IProfileProgramOverview, Program } from '@guider-global/shared-types';
import { format } from 'date-fns';
import {
  ProgramMembershipsGuideContent,
  ProgramMembershipsTraineeContent,
  ProgramMembershipsCard,
  UserData,
} from '../../../views/ProgramMembershipsCard';
import { IRelationshipsRow } from '../RelationshipsTableContainer';
import { useMemo } from 'react';
import { ProgramMembershipDetailsViewProps } from '@guider-global/ui';
import { useAdminRelationships } from 'hooks';

export type GroupProgramMembershipsContainerProps = {
  profileProgramOverview: IProfileProgramOverview;
  program: Program;
  userData: UserData;
};

export function GroupProgramMembershipsContainer({
  profileProgramOverview,
  program,
  userData,
}: GroupProgramMembershipsContainerProps) {
  const memberships = profileProgramOverview.memberships;
  const relationshipProfilesData = memberships.flatMap(
    (membership) => membership.relationshipProfiles,
  );

  // - Sanity base language hook
  const { baseLanguage } = useBaseLanguage({ localeCode: 'en_GB' });
  const baseLanguageSkillsLabel = baseLanguage?.globals?.skills?.skills_label;

  const { adminRelationships } = useAdminRelationships({});

  const traineeRelationships = adminRelationships().filter((relationship) => {
    const traineeProfile =
      relationship.traineeProfiles?.filter(
        (profile) => profile.id === userData.userProfileId,
      ) ?? [];
    return (
      relationship.programSlug === profileProgramOverview.programSlug &&
      traineeProfile.length > 0
    );
  });

  const guideRelationships = adminRelationships().filter((relationship) => {
    const guideProfile =
      relationship.guideProfiles?.filter(
        (profile) => profile.id === userData.userProfileId,
      ) ?? [];
    return (
      relationship.programSlug === profileProgramOverview.programSlug &&
      guideProfile.length > 0
    );
  });

  const programData = useMemo(() => {
    const guideMembership = memberships
      .filter(
        (membership) =>
          profileProgramOverview.programSlug === program?.metadata.id.current &&
          membership.role === 'guide',
      )
      .at(0);

    const traineeMembership = memberships
      .filter(
        (membership) =>
          profileProgramOverview.programName ===
            program?.metadata.program_name && membership.role === 'trainee',
      )
      .at(0);

    const programAvatar = buildSanityImageUrl({
      source: program?.program_details?.program_image.asset ?? '',
      width: 300,
    });
    const programCardTitle = program?.metadata.program_name || '';

    const guideCount = guideRelationships.length;
    const traineeCount = traineeRelationships.length;

    const guideCountLabel = `${guideCount} ${
      guideCount === 1 ? 'Mentor' : 'Mentors'
    }`;
    const traineeCountLabel = `${traineeCount} ${
      traineeCount === 1 ? 'Trainee' : 'Trainees'
    }`;

    const programCardDescription = `${guideCountLabel} • ${traineeCountLabel}`;

    return {
      programAvatar,
      programCardTitle,
      programCardDescription,
      traineeMembership,
      guideMembership,
    };
  }, [
    memberships,
    relationshipProfilesData,
    program?.program_details?.program_image.asset,
    program?.metadata.program_name,
    program?.metadata.id,
    profileProgramOverview.programSlug,
    profileProgramOverview.programName,
  ]);
  const {
    programAvatar,
    programCardTitle,
    traineeMembership,
    guideMembership,
  } = programData;
  // Trainee Membership
  const traineeMembershipData: ProgramMembershipsTraineeContent =
    useMemo(() => {
      //Trainee membership fields
      const formattedTraineeMembershipCreated = traineeMembership?.createdAt
        ? format(new Date(traineeMembership.createdAt), 'dd/MM/yy')
        : 'N/A';

      const traineeSkills = getFormattedSkills({
        role: 'trainee',
        skills: traineeMembership?.skills,
        baseLanguageSkillsLabel,
        newSanitySkills: program?.skills,
        oldSanitySkills: program?.registration?.skills,
      });
      const traineeFields = getFormattedProgramFields(
        traineeMembership,
        program,
      );

      traineeFields.unshift(traineeSkills);

      const traineeDetailsTitle = 'Mentee profile';
      const traineeDetailsDescription = traineeMembership
        ? `Profile Created: ${formattedTraineeMembershipCreated}`
        : '';

      const traineeMembershipDetails: ProgramMembershipDetailsViewProps = {
        title: traineeDetailsTitle,
        description: traineeDetailsDescription,
        fields: traineeFields,
      };

      let traineeRelationshipRows: IRelationshipsRow[] = [];
      if (traineeMembership && traineeRelationships.length > 0) {
        traineeRelationshipRows = traineeRelationships.map((relationship) => {
          return {
            id: relationship.id,
            blobPicture: relationship?.guideProfiles?.[0].picture,
            name: relationship.group?.title ?? '',
            description: relationship.createdAt
              ? format(new Date(relationship.createdAt), 'dd/MM/yy')
              : 'N/A',
            numberOfSessions: relationship?.sessions?.length?.toString() ?? '0',
            status: relationship.isConcluded,
            relationshipId: relationship.id,
            title: relationship.group?.title ?? '',
          };
        });
      }
      return {
        traineeMembershipDetails,
        traineeRelationshipRows,
        traineeTableEmptyState: 'The user has not joined any group',
      };
    }, [program, traineeMembership]);

  const guideMembershipData: ProgramMembershipsGuideContent = useMemo(() => {
    const formattedGuideMembershipCreated = guideMembership?.createdAt
      ? format(new Date(guideMembership.createdAt), 'dd/MM/yy')
      : 'N/A';

    const guideFields = getFormattedProgramFields(guideMembership, program);

    const guideDetailsTitle = 'Mentor profile';
    const guideDetailsDescription = guideMembership
      ? `Profile Created: ${formattedGuideMembershipCreated}`
      : '';

    const guideMembershipDetails: ProgramMembershipDetailsViewProps = {
      title: guideDetailsTitle,
      description: guideDetailsDescription,
      fields: guideFields,
    };

    let guideRelationshipRows: IRelationshipsRow[] = [];

    if (guideMembership && guideRelationships.length > 0) {
      guideRelationshipRows = guideRelationships.map((relationship) => {
        return {
          id: relationship.id,
          blobPicture: relationship?.guideProfiles?.[0].picture,
          name: relationship.group?.title ?? '',
          description: relationship.createdAt
            ? format(new Date(relationship.createdAt), 'dd/MM/yy')
            : 'N/A',
          numberOfSessions: relationship?.sessions?.length?.toString() ?? '0',
          status: relationship.isConcluded,
          relationshipId: relationship.id,
          title: relationship.group?.title ?? '',
        };
      });
    }

    return {
      guideMembershipDetails,
      guideRelationshipRows,
      guideTableEmptyState: 'The user has not created any group yet',
    };
  }, [guideMembership, program]);

  const groupTableHeaders = {
    name: 'Group name',
    status: 'Relationship Status',
    numberOfSessions: 'Session held',
    manage: 'Manage',
  };
  return (
    <ProgramMembershipsCard
      profileProgramOverview={profileProgramOverview}
      title={programCardTitle}
      description={''}
      avatarSrc={programAvatar}
      guideMembership={guideMembership}
      traineeMembership={traineeMembership}
      userData={userData}
      guideMembershipContent={guideMembershipData}
      traineeMembershipContent={traineeMembershipData}
      guideRelationshipTableHeaders={groupTableHeaders}
      traineeRelationshipTableHeaders={groupTableHeaders}
    />
  );
}
